<template>
  <div class="help">
    <div class="main">
      <h1>新書マップヘルプ</h1>
      <!-- <p>ひととおり完成してからスクリーンショットとともに作成します。</p> -->

      <h2>Step1. キーワードや文章で検索してみる</h2>

      <p>例えば「音楽と映画」というキーワードから、関連テーマを探してみよう。画面左上の検索ボックスに「音楽と映画」と入力して検索ボタンをクリックすると、円の中央から関連テーマが湧き出して、ゆっくり回りながら配置されます。これが「新書マップ」です。</p>
      <p>【上手な使い方】検索ボックスには、手がかりとなる単語やフレーズを入れたり、記事や論文などの長い文章をそのままコピー&amp;ペーストで貼り付けることも可能です。</p>

      <h2>Step2. 検索結果を見る</h2>
      <p>検索を行うと、画面右側の円上に白と黄色の丸が現れて「新書マップ」の形で検索結果が表示されます。円の内側の黄色い丸は「関連テーマ」を表し、円の外側の白い丸は「関連キーワード」 を表します。「関連テーマ」をクリックすると、そのテーマについて書かれた「新書リスト」が背表紙写真つきで表示されます。テーマによっては「読書ガイド」を読むことができます。</p>
      <p>【関連テーマ】人手で作られた約1000のテーマから、関連性の強いテーマが連想検索されます。新書リストに載った本の概要 ・目次や読書ガイドの文章が検索に使われます。</p>
      <p>【関連キーワード】検索された関連テーマの内容を要約するような言葉が選択されます。関連テーマの新書リストに載っている本の概要・目次や読書ガイドの文章から、システムの連想機能によって自動的に選択されます。</p>
      <p>【上手な使い方】テーマとキーワードの間の相互関連性を知ることができます。例えばテーマ「クラシック音楽入門」にマウスポインタをのせると、そのテーマと関連性のあるキーワード「音」、「音楽」、「曲」、「リズム」、「時代」、「世界」が光ります。逆に「歌」というキーワードにマウスポインタをのせると、それと関連性のあるテーマ「歌と日本人」、「大衆芸能」、「日本の唱歌・童謡」、「楽器」、「エレキギター」が光ります。テーマごとに光り方が異なるのは、関連性の強弱を光の大きさで表しているからです。</p>

      <h2>Step3. 関連テーマからさらに探す</h2>

      <p>検索結果を表示している「新書マップ」から、気になるテーマをいくつか選択して、それらを基点として連想検索を続けることができます。テーマの選択は次のようなマウス操作で行います。</p>
      <p>画面左側にも関連テーマの一覧がリストの形で表示されています。このリストを使っても関連テーマからの再検索が可能です。</p>

      <h2>Step4. 関連キーワードからさらに探す</h2>

      <p>検索結果を表示している「新書マップ」から、気になるキーワードを１つ選択して、それを基点として連想検索を続けることができます。検索結果に白い丸として表示されているキーワードをクリックすると、その言葉を使って再検索されます。</p>
      <p>画面左側にも関連キーワードの一覧がリストの形で表示されています。このリストを使っても関連キーワードからの再検索が可能です。これにより、複数のキーワードを指定した再検索が可能です。</p>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Help',
  components: {
  },
  metaInfo() {
    return {
      title: '新書マップヘルプ',
    }
  },
}
</script>

<style lang="scss" scoped>
.main {
  max-width: 1024px;
  min-height: calc(100vh - 2.55rem);
  margin: 0 auto 1rem;
  padding: 4.5rem 1rem 1rem;
  box-shadow: 0 0 15px rgba(1, 35, 56, 0.3);
  border-bottom: 5px solid rgb(0, 99, 158);
  background: #FFF;
  text-align: left;
}

h1 {
  display: inline-block;
  padding: 2rem 1rem;
  margin: .5rem 0 1rem;
  font-weight: bold;
  background-color: #deeef6;
  border-left: solid 2rem #0081c1;
}

h2 {
  margin: 2rem 0 1rem;
  padding: .25rem .5rem;
  font-size: .95rem;
  font-weight: bold;
  color: #FFF;
  background-color: #0081c1;
}

p {
  text-indent: 1rem;
  margin-bottom: .5rem;
  line-height: 1.4rem;
}

a {
  color: #0066c0;
}

@media (max-width: 1024px) {
  .main {
    margin: 0 .5rem;
  }
}

@media (max-width: 512px) {
  .main {
    padding: 3.5rem .5rem 1rem;
  }
}
</style>
