<template>
  <footer v-show="showFooter">
    <div class="copyright">Copyright &copy; 2006 <a href="http://rensou.info/" target="_new">Association Press</a>. All Rights Reserved.</div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    showFooter() {
      return this.$route.name !== 'Home'
    },
  },
}
</script>

<style scoped>
footer {
  max-width: 1024px;
  margin: 0 auto .25rem;
  padding: .25rem 0;
}

.copyright {
  text-align: right;
  font-size: .8rem;
}

@media (max-width: 1024px) {
  footer {
    padding: .25rem .5rem;
  }
}

</style>
