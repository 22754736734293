import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta'
import VTooltip from 'v-tooltip'
// import VueGtag from "vue-gtag";

Vue.use(VueMeta)
Vue.config.productionTip = false

Vue.use(VTooltip, {
  defaultDelay: 500
})

// Vue.use(VueAnalytics, {
//   id: 'G-K9D1RLTCX5'
// })

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
