<template>
  <div class="assoc">
    <div class="theme-assoc-header">
      <div class="imagine-title">
        <a href="http://imagine.bookmap.info/imagine"><img class="imagine-logo" src="@/assets/IMAGINE2.svg" alt="想・IMAGINE"></a>
      </div>
      <div class="imagine-btn-wrap">
        <button class="imagine-btn" @click="buttonTo()" :disabled="! imagineOn">IMAGINE</button>
      </div>
    </div>
    <div class="shinshomap-theme">新書マップ・テーマ</div>
    <ul class="themes">
      <li v-for="(theme, i) in assoc"
        class="theme-li"
        :class="{'checked': themeChecked[i]}"
        :key="theme['@_name']">
        <label>
          <h3 class="assoc-theme-title">
            <input type="checkbox" v-model="themeChecked[i]" class="keyboard-focus">
            <router-link :to="getThemeTo(theme['@_name'])">{{ theme['@_title'] }}</router-link>
          </h3>
          <p class="assoc-theme-desc">{{ theme['@_desc'] }}</p>
        </label>
      </li>
    </ul>
  </div>
</template>


<script>
import GETAssoc from '@/modules/getassoc'
import IMAGINE from '@/modules/imagine'

export default {
  name: 'ThemeAssoc',
  props: ["theme"],
  data() {
    return {
      assoc: [],
      themeChecked: [],
      imagineOn: false,
    }
  },
  watch: {
    theme() {
      this.assocTheme()
    },
    themeChecked: {
      handler(tc) {
        this.imagineOn = false
        tc.forEach( async (t, i) => {
          if ( t == true ) {
            if ( ! this.assoc[i].vector ) {
              this.assoc[i].vector = await this.getVector(this.assoc[i]['@_name'])
            }
            this.imagineOn = true
          }
        })
      },
      deep: true
    },
  },
  mounted() {
    this.assocTheme()
  },
  methods: {
    async assocTheme() {
      if ( ! this.theme ) return
      const getassoc = new GETAssoc()
      getassoc.setProps('title,desc')
      getassoc.makeArticleQuery2([this.theme])
      const result = await getassoc.assoc()
      this.assoc = result.articles?.cls?.article || []
      this.assoc.forEach( (theme, i) => {
        this.themeChecked[i] = false
      })
    },
    getThemeTo(theme) {
      return `/theme/${theme}.html`
    },
    async getVector(name) {
      const imagine = new IMAGINE()
      return await imagine.getVector(name)
    },
    buttonTo() {
      const selectedThemes = this.assoc.filter( (t, i) => this.themeChecked[i] )
      if ( selectedThemes.length ) {
        const imagine = new IMAGINE()
        imagine.buttonTo(selectedThemes)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.assoc {
  width: 200px;
  margin: 0 0 1rem 1rem;
  padding-top: 3.5rem;
  border-bottom: 5px solid #00639e;
  background-color: #FFF;
}

.theme-assoc-header {
  border-left: 1px solid #CCC;
  border-right: 1px solid #CCC;
  padding: 1rem 0 .5rem;
}

.imagine-title {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 2px solid rgb(0, 99, 158);
  margin-bottom: .7rem;
}

.imagine-title > a {
  margin-bottom: -.4rem;
}

.imagine-logo {
  height: 1.5rem;
}

.imagine-btn-wrap {
  margin-top: .5rem;
}

.imagine-btn {
  padding: 0 2rem;
  line-height: 1.4rem;
  border: solid 1px #333;
  background-color: #EEE;
  border-radius: .1rem;
  font-size: .9rem;
  font-weight: bold;
}

.imagine-btn:disabled {
  color: #A8A8A8;
  border-color: #BBB;
}

.imagine-btn:focus {
  outline: solid;
}
.imagine-btn:focus:not(:focus-visible) {
  outline: 0;
}

.shinshomap-theme {
  width: 200px;
  padding: .1rem .5rem;
  color: #FFF;
  background-color: #0951A2;
  text-align: left;
  font-size: .9rem;
  font-weight: bold;
  line-height: normal;
  white-space: nowrap;
}

.themes {
  border-top: 1px solid #CCC;
  border-left: 1px solid #CCC;
  border-right: 1px solid #CCC;
}

.theme-li {
  border-bottom: 1px solid #CCC;
  font-size: .9rem;
  padding: .1rem;
  text-align: left;
}

.theme-li > label {
  display: block;
  padding: .5rem;
  transition: background-color .2s;
}

.theme-li.checked > label {
  background-color: rgb(183, 211, 244);
}

.assoc-theme-title {
  margin-bottom: .3rem;
  font-weight: bold;
}

.assoc-theme-title > a:hover {
  text-decoration: underline;
}

.assoc-theme-title > input {
  display: inline-block;
  margin-right: .5rem;
  vertical-align: middle;
}

.assoc-theme-title > a {
  vertical-align: middle;
}

.assoc-theme-desc {
  line-height: 1.2rem;
}

@media (max-width: 960px) {
  .assoc {
    width: calc(100% - 2rem);
    padding-top: 0;
  }
  .shinshomap-theme {
    width: auto;
  }
}

</style>
