const menu = [
  {
    title: 'ホーム',
    link: '/'
  },
  {
    title: '書棚で見るテーマ一覧',
    link: '/genre/'
  },
  {
    title: '新書に訊け!',
    link: 'http://kaze.shinshomap.info/ask/'
  },
  {
    title: '新書マップについて',
    link: '/about/'
  },
  {
    title: '新書マップヘルプ',
    link: '/help/'
  },
  {
    title: 'お知らせ',
    link: '/notice/'
  }
]

export default menu
