<template>
  <div class="about">
    <div class="main">
      <h1>新書マップについて</h1>
      <p>「新書マップ」は、まったく新しいスタイルの読書案内を提供するシステムです。入手しやすく読書の基点とするのに適当な新書・選書を対象に、そこで扱われている現代的な1000テーマを抽出しました。各テーマについて、関連する本のリスト、本の概要や目次、それらを読み解くための読書ガイドが付けられています。キーワードや文章を丸ごと質問文にできる「連想検索機能」により、漠然とした要求からでも関連テーマ10個をたちどころに探し出し、星座表のような関連図（新書マップ）を作成します。</p>

      <p>新書マップは、国立情報学研究所 高野研究室の研究プロジェクト「新書マッププレス」が構築し、2004年6月30日に公開しました。活動の継続的発展のため、2005年12月よりNPO法人「<a href="http://rensou.info/" target="_new">連想出版</a>」が引き継いで運営しています。</p>

      <h2>収録データについて</h2>
      <p>{{ update }}現在、{{ total }}冊のノンフィクション・教養系の新書・選書が収録されています。{{ date }}までに発行された本が対象となっています。今後とも毎月1回のペースで新刊を取り入れていく予定です。ただし、適切なテーマが見つかった本だけが収録されるため、すべての新書が網羅的に登録されている訳ではないことに注意してください。 </p>

      <h2>著作権及びリンクについて</h2>
      <p>本Webサイトに含まれるすべてのコンテンツは、NPO法人連想出版またはコンテンツ提供者の財産です。本サイトに含まれるすべてのコンテンツの編集物は、NPO法人連想出版の独占的な財産です。本サイトで使用されているすべてのソフトウェアは、NPO法人連想出版またはソフトウェア提供者の財産です。これらはすべて、日本の著作権法および著作権に関する国際法によって保護されています。 </p>
      <p>NPO法人連想出版に提供されている情報および画像等の無断転載をお断りいたします。なお、データの一部は「BOOKデータベース」（著作権者：(株)トーハン、日本出版販売(株)、日外アソシエーツ(株)、(株)紀伊国屋書店）のデータベースを利用しています。</p>
      <p>いうまでもありませんが、本Webサイトへのリンクは自由に行っていただいてかまいません。印刷媒体、CD-ROM、VTR、放送等で利用される場合には、事前にNPO法人連想出版までご連絡ください。</p>

      <h2>システムについて</h2>
      <p>新書マップでは、連想検索機能を実現するために、<a href="https://getassoc.cs.nii.ac.jp/" target="_new">汎用連想計算エンジンGETAssoc</a>を使用しています。連想検索機能の詳細については、「<a href="https://getassoc.cs.nii.ac.jp/docs/association/" target="_new">連想検索について</a>」をごらんください。</p>

      <h2>お問い合わせ先</h2>
      <p>NPO法人連想出版（代表：高野明彦）E-mail: info アット associationpress.jp
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  metaInfo() {
    return {
      title: '新書マップについて',
    }
  },
  data() {
    return {
      date: '',
      total: '',
      update: ''
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      const api = `/shinshomap_api/index.json`
      fetch(api, {mode: 'cors', credentials: 'include'})
        .then( response => {
          if ( response.status >= 400 ) {
            this.$router.replace('/not-found')
            return
          }
          return response.json()
        })
        .then( json => {
          if ( ! json?.data ) return
          const update = new Date(json.data.lastmod)
          this.update = `${update.getFullYear()}年${update.getMonth()+1}月${update.getDate()}日`
          this.date = json.data.bookInfo[0].lastpub
          this.total = json.data.bookInfo[0].total
        })
    },
  }
}
</script>

<style lang="scss" scoped>
.main {
  max-width: 1024px;
  margin: 0 auto 1rem;
  padding: 4.5rem 1rem 1rem;
  box-shadow: 0 0 15px rgba(1, 35, 56, 0.3);
  border-bottom: 5px solid rgb(0, 99, 158);
  background: #FFF;
  text-align: left;
}

h1 {
  display: inline-block;
  padding: 2rem 1rem;
  margin: .5rem 0 1rem;
  font-weight: bold;
  background-color: #deeef6;
  border-left: solid 2rem #0081c1;
}

h2 {
  margin: 2rem 0 1rem;
  padding: .25rem .5rem;
  font-size: .95rem;
  font-weight: bold;
  color: #FFF;
  background-color: #0081c1;
}

p {
  text-indent: 1rem;
  margin-bottom: .5rem;
  line-height: 1.4rem;
}

a {
  color: #0066c0;
}

@media (max-width: 1024px) {
  .main {
    margin: 0 .5rem;
  }
}

@media (max-width: 512px) {
  .main {
    padding: 3.5rem .5rem 1rem;
  }
}
</style>
