<template>
  <div class="book-assoc">
    <div class="book-assoc-header">
      <img src="@/assets/right.svg"><span>関連新書</span>
    </div>
    <div class="book-assoc-books-wrap">
      <ul class="book-assoc-books" id="book-assoc-books">
        <li v-for="book in assoc"
          class="book-assoc-li"
          :key="book['@_name']">
          <router-link :to="getBookTo(book['@_isbn13'])">
            <div class="book-assoc-img">
              <img v-if="book['@_img']" :src="getBookImage(book['@_img'])">
              <img v-else src="@/assets/noimage.jpg">
            </div>
            <p class="book-assoc-info">
              <span class="book-assoc-title">{{ book['@_title'] }}</span><br>
              {{ book['@_author'] }},
              {{ book['@_publish_date'] }}
            </p>
          </router-link>
        </li>
      </ul>
      <button class="book-assoc-right" @click="scroll('right')">
        <img src="@/assets/right-btn-box.svg">
      </button>
      <button class="book-assoc-left" @click="scroll('left')">
        <img src="@/assets/left-btn-box.svg">
      </button>
    </div>
  </div>
</template>


<script>
import GETAssoc from '@/modules/getassoc'

export default {
  name: 'BookAssoc',
  props: ["book"],
  data() {
    return {
      assoc: [],
    }
  },
  watch: {
    book() {
      this.assocBook()
    },
  },
  mounted() {
    this.assocBook()
  },
  methods: {
    async assocBook() {
      if ( ! this.book ) return
      const elem = document.getElementById("book-assoc-books")
      elem.style.scrollBehavior = 'auto'
      elem.scrollLeft = 0
      const getassoc = new GETAssoc('shinshomap_book')
      getassoc.setProps('title,author,publish_date,img,isbn13')
      getassoc.makeArticleQuery2([this.book], 11)
      const result = await getassoc.assoc()
      this.assoc = result.articles?.cls?.article.slice(1) || []
      elem.style.scrollBehavior = 'smooth'
    },
    getBookTo(isbn13) {
      return `/book/${isbn13}.html`
    },
    getBookImage(src) {
      if ( ! src ) return
      return src.replace('http://shinshomap.associationpress.jp/api',
                         '/shinshomap_api')
    },
    scroll(dir) {
      const elem = document.getElementById("book-assoc-books")
      if ( dir === 'right' )
        elem.scrollLeft += elem.offsetWidth
      else
        elem.scrollLeft -= elem.offsetWidth
    }
  }
}
</script>

<style lang="scss" scoped>
.book-assoc {
  margin: 3rem 0 2rem;;
}

.book-assoc-header {
  text-align: left;
  margin-bottom: 1rem;
}

.book-assoc-header > img {
  width: 1.2rem;
  vertical-align: middle;
  padding-right: .25rem;
}

.book-assoc-header > span {
  vertical-align: middle;
}

.book-assoc-books-wrap {
  position: relative;
  overflow: hidden;
}

.book-assoc-books {
  display: flex;
  flex-direction: row;
  overflow: scroll;
  scroll-behavior: smooth;
}

.book-assoc-li {
  display: block; 
}

.book-assoc-li > a {
  display: block;
  width: 154px;
  padding: .25rem;
}

.book-assoc-title {
  color: #106a96;
}

.book-assoc-li > a:hover .book-assoc-title {
  text-decoration: underline;
}

.book-assoc-img {
  display: flex;
  align-items: flex-end;
  height: 165px;
  width: 100px;
  margin: 0 auto;
  overflow: hidden;
}

.book-assoc-img > img {
  width: 100%;
}

.book-assoc-info {
  margin-top: .5rem;
  font-size: .9rem;
  text-align: left;
}

.book-assoc-right,
.book-assoc-left {
  position: absolute;
  width: 18px;
  height: 50px;
  top: 70px;
  line-height: 50px;
  background-color: #FFF;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.book-assoc-right:hover,
.book-assoc-left:hover {
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
}

.book-assoc-right > img,
.book-assoc-left > img {
  width: 12px;
  vertical-align: middle;
  opacity: .5;
}

.book-assoc-right:hover > img,
.book-assoc-left:hover > img {
  opacity: 1.0;
}

.book-assoc-right {
  right: 0;
}

.book-assoc-left {
  left: 0;
}

@media (max-width: 512px) {
  .book-assoc-li > a {
    width: 120px;
  }
}

</style>
