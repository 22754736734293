import GETAssoc from '@/modules/getassoc'

const url = {
  vector: 'https://imagine.bookmap.info/bar/vector',
  // vector: 'https://teotihuacan.cs.nii.ac.jp/imagine_api/bar/vector',
  post: 'https://imagine.bookmap.info/imagine',
  imagine: 'https://imagine.bookmap.info/imagine',
  imagine_bar: 'https://imagine.bookmap.info/',
}

class IMAGINE {
  constructor() {
  }

  post(data) {
    const form = document.createElement('form')
    form.setAttribute('action', url.post)
    form.setAttribute('target', 'external')
    form.setAttribute('method', 'POST')

    this.appendChild(form, 'freetext', data.join(','))
    this.appendChild(form, 'corpus_add', 'shinshomap.shinshomap_theme')
    this.appendChild(form, 'corpus_add_src', 'shinshomap.shinshomap_theme')

    const body = document.getElementsByTagName('body')[0]
    body.appendChild(form)
    form.submit()
    body.removeChild(form)
  }

  async getVector2(theme) {
    const method = 'POST'
    const params = {
      target: 'shinshomap.shinshomap_theme',
      id: theme
    }
    const body = Object.keys(params).map( key => {
      return key+"="+encodeURIComponent(params[key])
    }).join("&")
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
    };
    const mode = 'cors'
    const credentials = 'include'
    const response = await fetch(`${url.vector}?t=${Date.now()}`, {method, headers, body, mode, credentials})
    const text = await response.text()
    return text
  }

  async getVector(theme) {
    const getassoc = new GETAssoc('shinshomap_theme')
    getassoc.makeVectorQuery(theme)
    const result = await getassoc.assoc()
    return result.article['@_vec']
  }

  buttonTo(themes) {
    const form = document.createElement('form')
    form.setAttribute('action', url.imagine_bar)
    form.setAttribute('name', 'searchForm')
    form.setAttribute('target', 'imagine')
    form.setAttribute('method', 'POST')
    themes.forEach( theme => {
      this.appendChild(form, 'article[]',
                       `&target=shinshomap.shinshomap_theme&id=${theme['@_name']}`)
      if ( theme.vector ) {
        let vector = `shinshomap.shinshomap_theme:+:${theme['@_name']}:-:${theme.vector}`
        this.appendChild(form, 'article_vector[]', vector)
      }
    })
    const body = document.getElementsByTagName('body')[0]
    body.appendChild(form)
    form.submit()
    body.removeChild(form)
  }

  appendChild(parent, name, value) {
    const input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', name)
    input.setAttribute('value', value)
    parent.appendChild(input)
  }

}

export default IMAGINE

