<template>
<div>
  <button class="shop-title keyboard-focus" @click="toggleList()">
    <div class="shop-title-left">
      <img src="@/assets/cart.svg"> <span>ネット書店で購入</span>
    </div>
    <div><img class="down" :class="{'showing': showList}" src="@/assets/down-btn-blue.svg"></div>
  </button>
  <ul v-show="showList" class="shop-list">
    <li class="shop" v-for="shop in shopList" :key="shop.name">
      <a :href="getUrl(shop)" target="_new">
        <img src="@/assets/right-btn-blue.svg"> <span>{{ shop.name }}</span>
      </a>
    </li>
  </ul>
</div>
</template>

<script>

const shopList = [
  {
    name: 'Amazon',
    url: 'https://www.amazon.co.jp/dp/{parameter}',
    parameter: 'isbn10'
  },
  {
    name: 'honto',
    url: 'https://honto.jp/redirect.html?bookno={parameter}',
    parameter: 'isbn13',
    length: 12
  },
  {
    name: 'e-hon 全国書店ネットワーク',
    url: 'https://www.e-hon.ne.jp/bec/SA/Detail?refISBN={parameter}',
    parameter: 'isbn13'
  },
  {
    name: '紀伊國屋書店',
    url: 'https://www.kinokuniya.co.jp/f/dsg-01-{parameter}',
    parameter: 'isbn13'
  },
  {
    name: 'セブンネットショッピング',
    url: 'https://7net.omni7.jp/detail_isbn/{parameter}',
    parameter: 'isbn13'
  },
  {
    name: 'HMV&BOOKS online',
    url: 'https://www.hmv.co.jp/search/books/adv_1/barcodecatnum_{parameter}/category_5/target_DETAIL_BOOK/',
    parameter: 'isbn13'
  },
  {
    name: '楽天ブックス',
    url: 'https://books.rakuten.co.jp/search?sitem={parameter}&g=001&l-id=pc-search-box&x=0&y=0',
    parameter: 'isbn13'
  },
  {
    name: 'Honya Club.com',
    url: 'https://www.honyaclub.com/shop/goods/search.aspx?keyw=&title=&aut_n=&pub_n=&cat_t=00&ser_n=&bo_ver=&isbn={parameter}&pri_mi=&pri_ma=&yy_mi=&mm_mi=&yy_ma=&mm_ma=&sb.x=0&sb.y=0&cat_p=00&search_detail.x=x',
    parameter: 'isbn13'
  },
  {
    name: 'TSUTAYA online',
    url: 'http://shop.tsutaya.co.jp/book/product/{parameter}/',
    parameter: 'isbn13'
  },
  {
    name: 'ヨドバシカメラ',
    url: 'https://www.yodobashi.com/?word={parameter}',
    parameter: 'isbn13'
  }
]

export default {
  name: 'Shop',
  props: ["book"],
  data() {
    return {
      shopList: shopList,
      showList: false
    }
  },
  methods: {
    getUrl(shop) {
      if ( this.book.isbn13 === undefined ) return ''
      let param
      if ( shop.length && this.book[shop.parameter] ) { // for honto
        param = this.book[shop.parameter].substring(0, shop.length)
      } else if ( shop.parameter == 'isbn10' ) { // for Amazon
        param = this.book.isbn.substring(0, 1) == '4' ? this.book.isbn : toIsbn10(this.book.isbn13) 
      } else {
        param = this.book[shop.parameter]
      }
      return shop.url.replace('{parameter}', param)
    },
    toggleList() {
      this.showList = ! this.showList
    }
  }
}

const toIsbn10 = isbn13 => {
  let isbn10 = isbn13.replace(/^978/, '')
  isbn10 = isbn10.substring(0, 9)
  const numbers = isbn10.split('')
  let total = 0
  for(let i=0; i<numbers.length; ++i) {
    total += numbers[i] * (10-i)
  }
  let checkdigit = 11 - (total % 11)
  if ( checkdigit == 10 ) {
    checkdigit = 'X'
  } else if ( checkdigit == 11 ) {
    checkdigit = '0'
  } else {
    checkdigit = String(checkdigit)
  }
  isbn10 += checkdigit
  return isbn10
}


</script>

<style scoped>
.shop-title {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 1rem);
  margin: 1rem 0 .5rem;
  padding: .25rem .3rem;
  background-color: rgb(222, 238, 246);
  font-size: .9rem;
  text-align: left; 
  line-height: normal;
  user-select: none;
  cursor: pointer;
  border-radius: .25rem;
  transition: background-color .3s;
}
.shop-title:hover {
  background-color: rgb(167 226 255);
}

.shop-title-left > span,
.shop-title-left > img,
.shop > a > span,
.shop > a > img {
  vertical-align: middle;
}

.shop-title-left > img {
  width: .85rem;
}

.shop-list {
  margin-bottom: 1rem;
  padding-left: .25rem;
  text-align: left;
  user-select: none;
}

.shop {
  margin-bottom: .25rem;
}

.shop > a:hover {
  text-decoration: underline;
}

.shop > a > img {
  width: .5rem;
}

.down {
  width: 1rem;
  transition: transform .5s;
}

.down.showing {
  transform: rotate(-180deg);
}

@media (max-width: 512px) {
  .shop-title {
    width: 100%;
  }
}
</style>
