<template>
  <div class="menu-bk" @click="hideMenu">
    <div class="header">
      <ul>
        <li v-for="m in menuList" :key="m.link">
          <template v-if="m.link.substring(0,4) == 'http'">
            <a :href="m.link">
              <span>{{ m.title }}</span>
            </a>
          </template>
          <template v-else>
            <router-link :to="m.link">
              <span>{{ m.title }}</span>
            </router-link>
          </template>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import menuList from '@/modules/menu.js'

export default {
  name: 'Menu',
  data() {
    return {
      menuList: menuList
    }
  },
  methods: {
    hideMenu() {
      this.$emit("hide-menu")
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-bk {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.header {
  position: relative;
  max-width: 1024px;
  margin: 0 auto;
}

ul {
  position: absolute;
  top: 3.5rem;
  right: 0;
  border-style: solid;
  border-color: #999;
  border-width: 1px 1px 0 1px;
  background-color: #FFF;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, .3);
}

li {
  display: block;
  text-align: left;
  border-bottom: solid 1px #999;

  a {
    display: inline-block;
    width: 100%;
    padding: .5rem;

    &:hover {
      background-color: #deeef6;
    }
  }
}


@media (max-width: 1030px) {
  ul {
    right: 1rem;
  }
}

@media (max-width: 512px) {
  ul {
    top: 2.75rem;
    right: .5rem;
  }
}

</style>
