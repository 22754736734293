<template>
  <div :class="$style.book">
    <div :class="$style.wrap">     
      <div :class="$style.main">

        <div v-show="prevTheme" :class="$style.breadcrumb">
          <button @click="historyBack()" class="keyboard-focus">
            <img src="@/assets/left-double.svg"> <span>{{ prevTheme }}</span>
          </button>
        </div>

        <div :class="$style.info">
          <div :class="$style.info_second">
            <div :class="$style.book_wrap">
              <div :class="$style.book_name">
                <div>図書情報<br>Book Info.</div>
              </div>
              <div :class="$style.book_title">
                <div>{{ data.book.title }}</div>
              </div>
            </div>
            <div :class="$style.description">
              <p>{{ data.book.author }}</p>
              <p>{{ data.book.publisher }}, {{ data.book.publishDate }},
                {{ data.book.page }}, ({{ data.book.seriesInfo }})</p>
              <p>{{ isbn }}</p>
            </div>
          </div>
        </div>

        <div v-show="finishedFetch" :class="$style.shelf_book">
          <div :class="$style.shelf_side">
            <div :class="$style.shelf">
              <div :class="$style.shelf_border">
                <div :class="$style.shelf_left"></div>
                <img v-if="data.book.image"
                  :class="$style.shelf_image"
                  :src="getBookImage(data.book.image)"
                  @click="showModal(data.book.image)">
                <img v-else :class="$style.shelf_image" src="@/assets/noimage.jpg">
                <div :class="$style.shelf_right"></div>
              </div>
            </div>

            <div :class="$style.contain_theme">
              <div :class="$style.contain_theme_header">
                <img src="@/assets/right-double.svg"> <span>所属テーマ</span>
              </div>
              <ul>
                <li v-for="t in data.book.themes" :key="t['id']"
                  :class="$style.contain_theme_li">
                  <router-link :to="getThemeTo(t['nameEn'])">
                    <img src="@/assets/also-in-s.svg"> <span>{{ t['name'] }}</span>
                  </router-link>
                </li>
              </ul>
            </div>

            <div :class="$style.shelf_relate">
              <button @click="toImagine()" class="keyboard-focus">
                <span>関連書籍を探す</span> <img src="@/assets/external.svg">
              </button>
            </div>

            <Shop :book="data.book"></Shop>
          </div>
          
          <div :class="$style.books">
            <div :class="$style.list_header">内容</div>
            <p :class="$style.abstract">{{ data.book.description }}</p>
            <div v-if="data.book.contents" :class="[$style.list_header, $style.list_header_toc]">目次</div>
            <p v-for="c in data.book.contents2" :key="c" :class="$style.content">
              {{ c }}
            </p>
            <div>
              <div :class="$style.page_top"></div>
            </div>
          </div>
        </div>

        <BookAssoc v-show="finishedFetch" :book="data.book.id"></BookAssoc>
      </div>

      <ThemeAssoc :theme="data.book.themes[0].nameEn"></ThemeAssoc>
    </div>
    <Modal :src="modalSrc"
      v-on:close="closeModal"
    />
  </div>
</template>

<script>
import ThemeAssoc from './ThemeAssoc.vue'
import Shop from './Shop.vue'
import BookAssoc from './BookAssoc.vue'
import Modal from './Modal.vue'
import IMAGINE from '@/modules/imagine'

export default {
  name: 'Book',
  components: {
    ThemeAssoc,
    Shop,
    BookAssoc,
    Modal,
  },
  metaInfo() {
    return {
      title: this.title,
      titleTemplate: '%s - 新書マップ'
    }
  },
  data() {
    return {
      title: '',
      book: '',
      prevTheme: '',
      modalSrc: '',
      data: {
        'book': {
          title: '',
          description: '',
          image: '',
          contents: '',
          contents2: [],
          themes: [
            {
              nameEn: ''
            }
          ],
          isbn13: '',
          isbn: ''
        }
      },
      finishedFetch: false
    }
  },
  computed: {
    isbn() {
      if ( this.data.book.isbn13 == this.data.book.isbn ) {
        return `ISBN: ${this.data.book.isbn13}`
      } else {
        return `ISBN: 新<${this.data.book.isbn13}>, 旧<${this.data.book.isbn}>`
      }
    }
  },
  watch: {
    $route(to, from) {  // eslint-disable-line no-unused-vars
      this.fetchData(from)
      window.scrollTo({
        top: 0,
      })
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData(from) {
      this.finishedFetch = false
      if ( ! this.$route.params.book ) return
      this.book = this.$route.params.book.replace('.html', '').replace('-', '')
      if ( this.book.substring(0, 1) == '4' ) {
        // replace() を使うとhistoryにURLをスタックせずに遷移できる
        // this.$router.replace(`/book/${isbn13}`)
      }

      const api = `/shinshomap_api/book.json?q=${this.book}`
      fetch(api, {mode: 'cors', credentials: 'include'})
        .then( response => {
          if ( response.status >= 400 ) {
            this.$router.replace('/not-found')
            return
          }
          return response.json()
        })
        .then( json => {
          if ( ! json ) return
          this.data = json
          if ( this.data.book.contents )
            this.data.book.contents2 = this.data.book.contents.split("\n")
          this.makeBackToTheme(from)
          this.title = this.data.book.title
          this.finishedFetch = true
        })
    },
    makeBackToTheme(from) {
      if ( from?.name == 'Theme' ) {
        const nameEn = from.params.theme.replace('.html', '')
        const theme = this.data.book.themes.filter( t => t['nameEn'] == nameEn )
        if ( theme.length ) {
          this.prevTheme = theme[0].name
        } else {
          this.prevTheme = ''
        }
      } else {
        this.prevTheme = ''
      }
    },
    getThemeTo(theme) {
      return `/theme/${theme}.html`
    },
    getBookTo(book) {
      return `/book/${book}.html`
    },
    getBookImage(src) {
      return src.replace('http://shinshomap.associationpress.jp/api',
                         '/shinshomap_api')
    },
    async toImagine() {
      const data = [this.data.book.title, this.data.book.description]
      const imagine = new IMAGINE()
      imagine.post(data.concat(this.data.book.contents2))
    },
    historyBack() {
      this.$router.go(-1)
    },
    showModal(src) {
      this.modalSrc = this.getBookImage(src)
    },
    closeModal() {
      this.modalSrc = ''
    },
  }
}
</script>

<style module>
.book {
  max-width: 1024px;
  margin: 0 auto;
}

.wrap {
  display: flex;
}

.main {
  flex-grow: 2;
  margin-bottom: 1rem;
  padding: 4.5rem 1rem 1rem;
  box-shadow: 0 0 15px rgba(0, 0, 0, .3);
  border-bottom: 5px solid rgb(0, 99, 158);
  overflow: hidden;
  background: #FFF;
}

.breadcrumb {
  text-align: left;
  font-size: .95rem;
  margin-bottom: .25rem;
}

.breadcrumb > button {
  cursor: pointer;
}

.breadcrumb > button:hover {
  text-decoration: underline;
}

.breadcrumb > button > span,
.breadcrumb > button > img {
  vertical-align: middle;
}

.breadcrumb > button > img {
  width: .85rem;
}


.info {
  width: 100%;
  margin-bottom: 1rem;
}

.info_second {
  display: flex;
}

.book_wrap {
  display: flex;
  width: 45%;
}

.book_wrap > div {
  display: flex;
  align-items: center;
}

.description {
  width: 55%;
  border-left: 1px solid white;
}

.category {
  width: 45%;
  padding: .05rem .5rem;
  border-bottom: solid 1px white;
  border-radius: .5rem 0 0 0;
  color: #FFF;
  background-color: rgb(0, 129, 193);
  text-align: left;
  font-weight: bold;
}

.book_name {
  padding: .2rem;
  color: #FFF;
  background-color: rgb(0, 129, 193);
  font-size: .8rem;
}

.book_title {
  flex-grow: 2;
  padding: 1rem;
  color: #000;
  background-color: rgb(222, 238, 246);
  text-align: left;
  font-weight: bold;
}

.description {
  padding: .5rem;
  background-color: rgb(232, 232, 232);
  text-align: left;
  font-size: .95rem;
  line-height: 1.4rem;
}

.shelf_book {
  display: flex;
}

.shelf_side {
  width: 45%;
}

.books {
  width: 55%; 
  text-align: left;
}

.shelf {
  width: calc(100% - 1rem);
  border-bottom: 1rem solid rgb(192, 173, 118);
  margin-bottom: .75rem;
}

.shelf_border {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  height: 340px;
  margin: 0 .5rem;
  background-color: rgb(221, 203, 145);
  box-sizing: content-box;
}

.shelf_left,
.shelf_right {
  width: 30px;
}

.shelf_image {
  height: 300px;
  cursor: pointer;
}

.shelf_right {
  border-bottom: 300px solid rgb(205, 187, 131);
  border-right: 30px solid transparent;
}
.shelf_border > a {
  display: block;
  z-index: 10;
}

.contain_theme {
  text-align: left;
  margin-bottom: 1rem;
  margin-right: 1rem;
  border-radius: .25rem;
  padding: .5rem 0 .25rem;
}

.contain_theme_header {
  margin-bottom: .5rem;
}

.contain_theme_li {
  margin-top: .5rem;
}

.contain_theme_li > a {
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: .25rem .4rem;
  border-radius: .25rem;
  background-color: rgb(222, 238, 246);
  transition: background-color .3s;
}

.contain_theme_li > a:hover {
  background-color: rgb(167 226 255);
}

.shelf_relate {
  color: #106a96;
  font-size: .9rem;
  text-align: left;
}

.shelf_relate > button {
  display: inline-block;
  cursor: pointer;
  padding-left: .2rem;
}


.contain_theme_header > span,
.contain_theme_header > img,
.contain_theme_li > a > span,
.contain_theme_li > a > img,
.shelf_relate > button > span,
.shelf_relate > button > img {
  vertical-align: middle;
}

.contain_theme_header > img,
.contain_theme_li > a > img,
.shelf_relate > button > img {
  width: .85rem;
}

.list {
  border-bottom: solid 1px #CCC;
}

.list_header {
  margin-bottom: .9rem;
  padding: .1rem .3rem;
  color: #FFF;
  background-color: rgb(0, 129, 193);
  font-size: .9rem;
  font-weight: bold;
  text-align: left; 
  line-height: normal;
}

.list_header_toc {
  margin-top: .9rem;
}

.book_info {
  margin: .25rem 0;
  line-height: 1.2rem;
  font-size: .9rem;
}

.other_themes {
  margin-top: 1.5rem;
  color: #006; 
  font-size: .9rem;
}

.abstract {
  text-indent: 1rem;
}

.books p {
  line-height: 1.25rem;
  margin-bottom: .35rem;
}

.content {
  padding-left: 1rem;
  text-indent: -1rem;
}
.content > img {
  width: .5rem;
  vertical-align: middle;
}

.also {
  display: inline-block;
  margin-right: .75rem;
  font-size: .8rem;
}

.also_desc {
  margin: .75rem 0;
  color: #F60;
  font-size: .9rem;
  text-align: left;
}

.page_top {
  font-size: .9rem;
  text-align: right;
}

@media (max-width: 960px) {
  .wrap {
    display: block;
  }
}

@media (max-width: 512px) {
  .main {
    padding: 4.5rem .5rem 0;
  }
  .info_second {
    display: block;
  }
  .shelf_book {
    display: block;
  }
  .book_wrap,
  .category,
  .description,
  .shelf,
  .shelf_side,
  .books {
    width: 100%;
  }
  .description {
    border-left: none;
    border-top: 1px solid white;
  }
  .shelf_border {
    width: calc(100% - 1.5rem);
  }
  .contain_theme {
    margin-right: 0;
  }
  .content {
    text-indent: -1rem;
    padding: 0 .5rem 0 1rem;
  }
}
</style>
