<template>
  <div id="container" class="container">
    <div class="map" :style="mapScale">
      <div class="centerCircle"></div>
      <div id="deco" class="deco"></div>

      <ul id="innerUl" class="innerUl">
        <li v-for="(t, i) in themes" :key="t['@_name']"
          :id="'innerLi' + i"
          @mouseover="mouseoverInner(t)"
          @mouseout="mouseoutInner(t)"
          @mousedown="mousedownInner(t)"
          @touchstart="mousedownInner(t)"
          @mouseup="mouseupInner(t)"
          @touchend="mouseupInner(t)"
          @touchcancel="mouseupInner(t)"
          class="innerLi"
          :class="[innerWeightClass[t['@_name']], innerStretchClass[t['@_name']], {'selected': selectTheme[t['@_name']]}]">
          <span class="innerCircle"><span></span></span>
          <span class="innerKeyword">{{ t['@_title'] }}</span>
        </li>
      </ul>

      <ul id="outerUl" class="outerUl" :class="outerUlClass">
        <li v-for="(k, i) in keywords" :key="k['@_name']"
          :id="'outerLi' + i"
          @mouseover="mouseoverOuter(k)"
          @mouseout="mouseoutOuter(k)"
          @click="clickOuter(k, i)"
          class="outerLi"
          :class="outerRelatedClass[k['@_name']]">
          <span class="outerCircle" :class="{'selected': selectKeyword[k['@_name']]}"></span>
          <span class="outerKeyword">{{ k['@_name'] }}</span>
        </li>
      </ul>

      <div v-show="reSearch" class="re-search" @click="clickReSearch()">
        <span>再 検 索</span>
        <svg viewBox="0 0 100 100" width="100px" height="100px">
          <path d="M50,3C37.5,3,25.7,7.9,16.8,16.8l8.5,8.5C31.9,18.6,40.7,15,50,15c19.3,0,35,15.7,35,35c0,19.3-15.7,35-35,35
            c-10.8,0-20.8-5-27.3-13.1l9.9-6.6l-31-16l1.6,35.5l9.5-6.3C21.4,89.9,35.1,97,50,97c25.9,0,47-21.1,47-47S75.9,3,50,3z"/>
        </svg>
      </div>
      <div v-show="stopOperate" class="stop-operate"></div>
    </div>

    <div class="navi">
      <form class="form1" @submit.prevent="doAssoc(0, 'search')">
        <div class="input-wrap">
          <div class="input-desc">キーワードか文章を入力。</div>
          <div id="input-box" class="input-box">
            <input type="text" class="assocForm keyboard-focus" v-model="query" />
            <span class="input-clear" @click="clearInput">✕</span>
          </div>
        </div>
        <div class="btn-wrap">
          <button type="submit" class="btn keyboard-focus">連想検索</button>
        </div>
      </form>
    </div>

    <div class="right-navi">
      <router-link to="/genre/" class="shelf-btn">
        <span>書棚で見るテーマ一覧</span>
        <img src="@/assets/right-btn-box.svg">
      </router-link>
      <a href="http://kaze.shinshomap.info/ask/" class="shelf-btn">
        <span>新書に訊け!</span>
        <img src="@/assets/right-btn-box.svg">
      </a>
    </div>

    <div class="relations">
      <div class="relation">
        <button id="relation-theme" class="relation-btn wide keyboard-focus" @click="toggleThemeList()">
          <span>関連テーマ</span>
          <span class="list-arrow" :class="{open: showThemeList}">▼</span>
        </button>
        <div v-if="showThemeList" class="relation-list">
          <ul class="relation-ul">
            <li v-for="theme in themes" :key="theme['@_name']">
              <label class="li-title">
                <input type="checkbox" v-model="selectTheme[theme['@_name']]">
                <span>{{ theme['@_title'] }}</span>
              </label>
              <div class="right-arrow">
                <router-link :to="getThemeTo(theme)"><img src="@/assets/right-btn-box.svg"></router-link>
              </div>
            </li>
          </ul>
          <div class="re-search-btn-wrap">
            <button class="re-search-btn" :class="{reSearch: reSearch}" @click="clickReSearch()">
              <svg viewBox="0 0 100 100" width="12.8" height="12.8">
                <path d="M50,3C37.5,3,25.7,7.9,16.8,16.8l8.5,8.5C31.9,18.6,40.7,15,50,15c19.3,0,35,15.7,35,35c0,19.3-15.7,35-35,35
                  c-10.8,0-20.8-5-27.3-13.1l9.9-6.6l-31-16l1.6,35.5l9.5-6.3C21.4,89.9,35.1,97,50,97c25.9,0,47-21.1,47-47S75.9,3,50,3z"/>
              </svg>
              <span>再 検 索</span>
            </button>
          </div>
        </div>
        <button class="relation-btn narrow keyboard-focus" @click="toggleThemeList()">
          <span>関連テーマ</span>
          <span class="list-arrow" :class="{open: showThemeList}">▲</span>
        </button>
      </div>

      <div class="relation">
        <button id="relation-keyword" class="relation-btn wide keyboard-focus" @click="toggleKeywordList()">
          <span>関連キーワード</span>
          <span class="list-arrow" :class="{open: showKeywordList}">▼</span>
        </button>
        <div v-if="showKeywordList" class="relation-list">
          <ul class="relation-ul">
            <li v-for="keyword in keywords" :key="keyword['@_name']">
              <label class="li-title">
                <input type="checkbox" v-model="selectKeyword[keyword['@_name']]">
                <span>{{ keyword['@_name'] }}</span>
              </label>
            </li>
          </ul>
          <div class="re-search-btn-wrap">
            <button class="re-search-btn keyboard-focus" :class="{reSearch: reSearch}" @click="clickReSearch()">
              <svg viewBox="0 0 100 100" width="12.8" height="12.8">
                <path d="M50,3C37.5,3,25.7,7.9,16.8,16.8l8.5,8.5C31.9,18.6,40.7,15,50,15c19.3,0,35,15.7,35,35c0,19.3-15.7,35-35,35
                  c-10.8,0-20.8-5-27.3-13.1l9.9-6.6l-31-16l1.6,35.5l9.5-6.3C21.4,89.9,35.1,97,50,97c25.9,0,47-21.1,47-47S75.9,3,50,3z"/>
              </svg>
              <span>再 検 索</span>
            </button>
          </div>
        </div>
        <button class="relation-btn narrow keyboard-focus" @click="toggleKeywordList()">
          <span>関連キーワード</span>
          <span class="list-arrow" :class="{open: showKeywordList}">▲</span>
        </button>
      </div>
    </div>

    <div class="map-copyright">
      Copyright &copy; 2006 Association Press. <br>
      All Rights Reserved.
    </div>

    <!-- <Tips /> -->

  </div>
</template>

<script>
// @ is an alias to /src
import GETAssoc from '@/modules/getassoc'
// import Tips from '@/components/Tips.vue'
const numDeco = 240;
let mousedownTimer = null

export default {
  name: 'Home',
  metaInfo() {
    return {
      title: '新書マップ',
    }
  },
  components: {
    // Tips,
  },
  data() {
    return {
      query: '例えば、働くことの意味',
      keywordQuery: '',
      themes: [],
      keywords: [],
      result: {},
      outerUlClass: {},
      innerShrink: false,
      innerStretch: false,
      outerRelatedClass: {},
      innerWeightClass: {},
      innerStretchClass: {},
      selectTheme: {},
      selectKeyword: {},
      reSearch: false,
      stopOperate: true,
      showThemeList: true,
      showKeywordList: false,
      zoom: 1.0,
    }
  },
  watch: {
    selectTheme: {
      handler(st) {
        this.reSearch = false
        for (const value of Object.values(st)) {
          if ( value ) {
            this.reSearch = true
            break
          }
        }
      },
      deep: true
    },
    selectKeyword: {
      handler(sk) {
        this.reSearch = false
        for (const value of Object.values(sk)) {
          if ( value ) {
            this.reSearch = true
            break
          }
        }
      },
      deep: true
    }
  },
  computed: {
    mapScale() {
      // const y = 70 - 0.5 * this.zoom * 900 
      // return {transform: `translate(-50%, ${y}px) scale(${this.zoom})`}
      return {transform: `scale(${this.zoom})`}
    }
  },
  // created() {
  //   if ( freetext ) {      // eslint-disable-line no-undef
  //     console.log(freetext) // eslint-disable-line no-undef
  //   }
  // },
  mounted() {
    this.init()
    this.resize()
    this.assoc()
    setTimeout( () => {
      this.afterAssoc()
    }, 500)
    window.addEventListener('resize', this.resize)
  },
  methods: {
    init() {
      const deco = document.getElementById("deco")
      for(let i=0; i<numDeco; ++i) {
        let div = document.createElement('div');
        div.classList.add('decoElem');
        deco.appendChild(div);
      }
      if (window.innerWidth <= 900) {
        this.showThemeList = false
      }
    },
    async assoc(method) {
      if (window.innerWidth <= 900) {
        this.showThemeList = false
        this.showKeywordList = false
      }

      if ( this.$route.name === 'SearchCompatibility' &&
           freetext !== '' ) {   // eslint-disable-line no-undef
        this.query = freetext    // eslint-disable-line no-undef
        freetext = ''            // eslint-disable-line no-undef
      }

      const getassoc = new GETAssoc()
      const gtagEvent = {
        'event_category': 'Shinshomap'
      }
      if ( method == 'keyword' ) {
        const queries = []
        if ( this.keywordQuery ) queries.push(this.keywordQuery)
        for (const [key, value] of Object.entries(this.selectKeyword)) {
          if ( value ) queries.push(key)
        }
        getassoc.makeKeywordQuery(queries)

        gtagEvent.event_label = 'keywords'
        gtagEvent.value = queries.join(',')

      } else if ( method == 'assoc' ) {
        const names = []
        for (const [key, value] of Object.entries(this.selectTheme)) {
          if ( value ) names.push(key)
        }
        getassoc.makeArticleQuery(names)

        gtagEvent.event_label = 'themes'
        gtagEvent.value = names.join(',')

      } else if ( this.query ) {
        getassoc.makeFreetextQuery(this.query)

        gtagEvent.event_label = 'freetext'
        gtagEvent.value = this.query.substring(0, 20)

      } else {
        return
      }

      if ( process.env.VUE_APP_GA_ID ) {
        this.$gtag.event("assoc", gtagEvent)
      }

      this.result = await getassoc.assoc()
    },
    doAssoc(i, method) {
      this.assoc(method)
      this.outerUlClass['shrink'+(i+1)] = true
      this.themes.forEach( t => {
        this.innerWeightClass[t['@_name']] = {}
        this.innerStretchClass[t['@_name']] = {}
        this.innerStretchClass[t['@_name']]["shrink"+t.radius] = true
      })
      this.stopOperate = true

      let assocTime = 3400
      setTimeout( () => {
        this.afterAssoc()
      }, assocTime)
    },
    afterAssoc() {
      this.selectTheme = {}
      this.selectKeyword = {}
      this.keywordQuery = ''

      setTimeout( () => {
        this.keywords = this.result.keywords.cls.keyword || []
        this.giveWeight()

        this.themes = this.result.articles.cls.article || []
        this.themes.forEach( t => {
          t.radius = Math.ceil(Math.random()*7+3)
          t.selected = false
        })
        this.stretch()
      }, 100)
    },
    giveWeight() {
      let maxScore = 0
      this.keywords.forEach( keyword => {
        keyword.article.forEach( article => {
          const s = parseFloat(article['@_score'])
          if ( s > maxScore ) {
            maxScore = s
          }
        })
      })
      this.keywords.forEach( keyword => {
        keyword.article.forEach( article => {
          const s = parseFloat(article['@_score'])
          article.weight = parseInt( 10 * s / maxScore )
        })
      })
    },
    stretch() {
      this.outerUlClass = {
        stretch: true,
        'stretch-after': true
      }
      this.themes.forEach( t => {
        this.innerStretchClass[t['@_name']] = {}
        this.innerStretchClass[t['@_name']]["stretch"+t.radius] = true
      })
      setTimeout( () => {
        this.outerUlClass['stretch'] = false
      }, 2800)
      setTimeout( () => {
        this.outerUlClass = {}
        this.stopOperate = false
      }, 2900)
    },
    selectInner(t) {
      const bool = ! this.selectTheme[t['@_name']]
      this.$set(this.selectTheme, t['@_name'], bool)
    },
    getThemeTo(t) {
      return `/theme/${t['@_name']}.html`
    },
    themeTo(t) {
      this.outerRelatedClass = {}
      this.$router.push({ name: 'Theme', params: { theme: t['@_name'] + '.html' } })
    },
    toggleThemeList() {
      this.showThemeList = ! this.showThemeList
      if ( this.showThemeList ) {
        this.showKeywordList = false
        this.selectKeyword = {}
      } else {
        this.selectTheme = {}
      }
    },
    toggleKeywordList() {
      this.showKeywordList = ! this.showKeywordList
      if ( this.showKeywordList ) {
        this.showThemeList = false
        this.selectTheme = {}
      } else {
        this.selectKeyword = {}
      }
    },

    //
    // event
    //
    clickOuter(k, i) {
      this.query = k['@_name']
      this.keywordQuery = k['@_name']
      this.doAssoc(i, 'keyword')
    },
    mousedownInner(t) {
      mousedownTimer = setTimeout( () => {
        mousedownTimer = null
        this.selectInner(t)
      }, 750)
    },
    mouseupInner(t) {
      if ( mousedownTimer ) {
        clearTimeout( mousedownTimer )
        mousedownTimer = null
        this.themeTo(t)
      }
    },
    mouseoverInner(theme) {
      this.outerUlClass = { normal: true }
      this.outerRelatedClass = {}
      if ( ! Array.isArray(theme.keyword) ) {
        return
      }
      theme.keyword.forEach( k => {
        if ( k['@_score'] == "0.000000e+00" ) return
        this.outerRelatedClass[k['@_name']] = {
          outerRelated: true
        }
      })
    },
    mouseoutInner() {
      this.outerRelatedClass = {}
    },
    mouseoverOuter(keyword) {
      this.innerWeightClass = {}
      if ( ! Array.isArray(keyword.article) ) {
        return
      }
      keyword.article.forEach( t => {
        this.innerWeightClass[t['@_name']] = {}
        this.innerWeightClass[t['@_name']]['innerWeight' + t.weight] = true
      })
    },
    mouseoutOuter() {
      this.innerWeightClass = {}
    },
    clickReSearch() {
      if ( ! this.reSearch ) return
      if ( Object.keys(this.selectKeyword).length ) {
        this.doAssoc(0, "keyword")
      } else {
        this.doAssoc(0, "assoc")
      }
      this.reSearch = false
    },
    resize() {
      const min = Math.min(window.innerWidth, window.innerHeight - 70)
      this.zoom = min < 900 ?  min / 900 : 1.0
    },
    clearInput() {
      this.query = '';
    },
  },
}
</script>

<style lang="scss">
//
// valiables
//
$RADIUS: 300;
$BORDER: 10;
$innerLiSize: 80;
$outerLiSize: 100;
$numInner: 10;
$numOuter: 12;
$r: $RADIUS + $BORDER + 60;
$unitDeg: 360 / $numOuter;

$innerUnitDeg: 360 / $numInner;
$innerR: $RADIUS - 40;

$numDeco: 240;
$decoUnitDeg: 360 / $numDeco;
$decoR: $RADIUS + $BORDER*2;

.container {
  position: relative;
  width: 100vw;
  height: 100vh;
  padding-top: 3.5rem;
  background: linear-gradient(to bottom, #3cb2df 0%, #1a4991 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

//
// center circle
//
.centerCircle {
  position: absolute;
  top: 50%;
  left: 50%;
  width: ($RADIUS * 2 + $BORDER * 2) + px;
  height: ($RADIUS * 2 + $BORDER * 2) + px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(135deg, #1e9bd7 0%, #1a4e96 100%);


  &::before {
    content: "";
    position: absolute;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    top: 0;
    left: 0;
    border-radius: 50%;
    transform: translate($BORDER + px, $BORDER + px);
    background: linear-gradient(135deg, #1a4e96 0%, #1e9bd7 100%);
  }
}


//
// Decoration around circle
//
.deco {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
}

.decoElem {
  position: absolute;
  width: 15px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  border-left: solid 5px white;
}

.decoElem {
  @for $t from 1 through $numDeco {
    &:nth-child(#{$t}) {
      transform: rotate($t * $decoUnitDeg + deg)
                 translateY($decoR + px)
                 rotate(90deg);
    }
  }
}

//
// main
//
.innerUl {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  color: #FFF;
  list-style-type: none;
}

.outerUl {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  color: #FFF;
  list-style-type: none;
}

.outerLi {
  position: absolute;
  top: (10 - $outerLiSize / 2) + px;
  left:(10 - $outerLiSize / 2) + px;
  width: $outerLiSize + px;
  height: $outerLiSize + px;
  text-align: center;
  display: flex;
  justify-content: center;

  @for $t from 1 through $numOuter {
    $rotate: $t * $unitDeg + (180 - $unitDeg);
    &:nth-child(#{$t}) {
      transform: rotate( $rotate + deg)
                 translateY($r + px)
                 rotate(-$rotate + deg);

      @if $t > $numOuter / 4 + 1 and $t < 3 * $numOuter / 4 + 1 {
        align-items: flex-end;
      } @else {
        align-items: flex-start;
      }
    }
  }

  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90px;
    height: 90px;
    opacity: 0;
    border-radius: 50%;
    /* will-change: transform; */
    transform: translate(-50%, -50%);
    background: radial-gradient(ellipse at center,
                                rgba(255, 255, 255, 0) 0%,
                                rgba(255, 255, 255, 1) 100%);
  }

  .outerCircle {
    display: inline-block;
    position: absolute;
    top: $outerLiSize / 2 + px;
    left: $outerLiSize / 2 + px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFF;
    transition: transform .8s;
  }

  .outerCircle.selected {
     background-color: rgb(255, 102, 0);
  }

  .outerKeyword {
    overflow: hidden;
    white-space: nowrap;
  }

  // hover
  &:hover {
    cursor: pointer;
  }

  &:hover::after {
    animation: outerLiHoverAfter .8s linear 1;
  }

  &:hover .outerCircle {
    animation: outerLiHoverOuterCircle .8s linear 1;
  }

  // related by inner
  &.outerRelated {
    @for $t from 1 through $numOuter {
      &:nth-child(#{$t}) {
        animation: outerRelated#{$t} .5s linear;
      }
    }
  }

  &:hover {
    @for $t from 1 through $numOuter {
      &:nth-child(#{$t}) {
        animation: outerHover#{$t} .5s linear;
      }
    }
  }

  &.stretch-after::before {
    display: none;
  }

  &.outerRelated::before {
    animation: outerRelatedBefore .75s linear;
    animation-fill-mode: forwards;
  }
  
  &::before {
    animation: outerRelatedBeforeReverse .4s linear;
    animation-fill-mode: forwards;
  }
}


.innerLi {
  position: absolute;
  top: (10 - $innerLiSize / 2) + px;
  left:(10 - $innerLiSize / 2) + px;
  width: $innerLiSize + px;
  height: $innerLiSize + px;
  text-align: center;
  z-index: 10;

  &.selected {
    z-index: 15;
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: (20+5) + px;
    height: (20+5) + px;
    opacity: 0;
    border: 3px solid rgb(255, 207, 67);  
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }

  /* &.selected::after {
     opacity: 1;
     border: 3px solid rgb(255, 102, 0);  
     border-radius: 0;
     } */

  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    width: 110px;
    height: 110px;
    opacity: 0;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background: radial-gradient(ellipse at center,
                                rgba(255, 255, 255, 1   ) 0%,
                                rgba(255, 255, 255, 0.01) 70%,
                                rgba(255, 255, 255, 0   ) 100%); 
    transition: opacity .5s, width .5s, height .5s;
  }

  &:hover {
    cursor: pointer;
  }

  &:hover::before {
    animation: innerLiBeforeHover .8s linear 1;
  }

  &:hover::after {
    animation: innerLiAfterHover .5s linear 1;
    animation-fill-mode: forwards;
  }

  &.selected:hover::after {
    animation: none;
  }

  @for $u from 1 through 10 {
    @for $t from 1 through $numInner {
      &.stretch#{$u}:nth-child(#{$t}) {
        animation: innerStretch#{$t}-#{$u} 2.25s cubic-bezier(0.225, 0.830, 0.30, 1.005);
        animation-fill-mode: forwards;
      }
    }

    &.stretch#{$u} .innerKeyword {
      display: block;
    }
  }

  .innerCircle {
    display: inline-block;
    position: absolute;
    top: ($innerLiSize / 2) + px;
    left: ($innerLiSize / 2) + px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(255, 207, 67);  
    pointer-events: none;
    
    span {
      display: inline-block;
    }    
  }

  &.selected .innerCircle {
    background-color: rgb(255, 102, 0);
  }

  &.selected .innerCircle::before,
  &.selected .innerCircle::after,
  &.selected .innerCircle > span::before,
  &.selected .innerCircle > span::after {
    content: "";
    position: absolute;
    width: .5rem;
    height: .5rem;
    border-style: solid;
    border-color: rgb(255, 102, 0);  
    border-radius: 0;
    z-index: 16;
  }

  &.selected .innerCircle::after {
    bottom: -6px;
    right: -6px;
    border-width: 0 2px 2px 0;
  }

  &.selected .innerCircle::before {
    top: -6px;
    left: -6px;
    border-width: 2px 0 0 2px;
  }

  &.selected .innerCircle > span::after {
    top: -6px;
    right: -6px;
    border-width: 2px 2px 0 0;
  }

  &.selected .innerCircle > span::before {
    bottom: -6px;
    left: -6px;
    border-width: 0 0 2px 2px;
  }

  .innerKeyword {
    position: absolute;
    display: none;
    width: (3 * $outerLiSize + 20) + px;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    pointer-events: none;
    overflow: hidden;
    white-space: nowrap;
  }

  @for $t from 0 through 10 {
    &.innerWeight#{$t}::before {
      opacity: 1.0;
      width: (1 + $t) * 20 + px;
      height: (1 + $t) * 20 + px;
    }
  }

  @for $u from 1 through 10 {
    @for $t from 1 through $numInner {
      &.shrink#{$u}:nth-child(#{$t}) {
        animation: innerShrink#{$t}-#{$u} 1.2s linear;
        animation-fill-mode: forwards;
      }
    }
    &.shrink#{$u} .innerKeyword {
      display: block;
      animation: innerKeywordShrink .1s linear .8s;
      animation-fill-mode: forwards;
    }
  }

}

.stretch {
  .outerLi {
    @for $t from 1 through $numOuter {
      &:nth-child(#{$t}) {
        animation: outerStretch#{$t} 1.5s cubic-bezier(0.230, 1.000, 0.320, 1.000);
        animation-fill-mode: forwards;
      }
    }
  }
  .outerLi::after {
    animation: outerStretchBefore 1.5s cubic-bezier(0.225, 0.830, 0.50, 1.00) 1.3s;
  }
}

@for $u from 1 through $numOuter {
  .shrink#{$u} {
    .outerLi {
      @for $t from 1 through $numOuter {
        &:nth-child(#{$t}) {
          animation: outerShrink#{$u}-#{$t}-1 1.4s cubic-bezier(0.225, 0.830, 0.5, 1.00) .8s alternate,
                     outerShrink#{$u}-#{$t}-2 1.2s ease-out 2.2s alternate forwards;
        }
      }
    }

    .outerCircle {
      transform: translate(-50%, -50%) scale(1.7)      
    }

    @for $t from 1 through $numOuter {
      .outerLi:nth-child(#{$t}) .outerKeyword {
        @if $t == $u {
          display: inline;
        } @else {
          display: none;
        }
      }
    }
  }
}


//
// Keyframes
//
@keyframes innerLiBeforeHover { 
  0% {
    opacity: 1.0;
    transform: translate(-50%, -50%) scale(.5)
  }
  50% {
    opacity: 1.0;
    transform: translate(-50%, -50%) scale(1.0)
  }
  100% {  
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.5)
  }
}

@keyframes innerLiAfterHover { 
  0% {
    opacity: 1.0;
    transform: translate(-50%, -50%) scale(1.0)
  }
  50% {
    opacity: 1.0;
    transform: translate(-50%, -50%) scale(1.2)
  }
  100% {
    opacity: 1.0;
    transform: translate(-50%, -50%) scale(1.0)
  }
}

@for $t from 1 through $numInner {
  @keyframes innerLiBeforeByOuter#{$t} { 
    0% {
      opacity: 1.0;
      transform: translate(-50%, -50%) scale(.5)
    }
    100% {  
      opacity: 1.0;
      transform: translate(-50%, -50%) scale(.5 + $t/7)
    }
  }
  @keyframes innerLiBeforeByOuterBack#{$t} { 
    0% {
      opacity: 1.0;
      transform: translate(-50%, -50%) scale(.5 + $t/7)
    }
    100% {  
      opacity: 1.0;
      transform: translate(-50%, -50%) scale(.5)
    }
  }
}


@keyframes outerLiHoverAfter { 
  0% {
    opacity: 1.0;
    transform: translate(-50%, -50%) scale(.5)
  }
  15% {
    opacity: 1.0;
    transform: translate(-50%, -50%) scale(1.0)
  }
  30% {
    opacity: 1.0;
    transform: translate(-50%, -50%) scale(1.0)
  }
  100% {  
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.5)
  }
}

@keyframes outerRelatedBefore { 
  0% {
    opacity: 1.0;
    transform: translate(-50%, -50%) scale(.5)
  }
  30% {
    opacity: 1.0;
    transform: translate(-50%, -50%) scale(1.5)
  }
  100% {  
    opacity: 1.0;
    transform: translate(-50%, -50%) scale(1.0)
  }
}

@keyframes outerRelatedBeforeReverse { 
  0% {
    opacity: 1.0;
    transform: translate(-50%, -50%) scale(1.0)
  }
  100% {  
    opacity: 1.0;
    transform: translate(-50%, -50%) scale(.1)
  }
}

@keyframes outerStretchBefore { 
  0% {
    opacity: 1.0;
    transform: translate(-50%, -50%) scale(.5)
  }
  100% {  
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.75)
  }
}

@keyframes outerLiHoverOuterCircle { 
  0% {
    transform: translate(-50%, -50%) scale(1.0)
  }
  15% {
    transform: translate(-50%, -50%) scale(1.25)
  }
  50% {
    transform: translate(-50%, -50%) scale(1.0)
  }
  100% {  
    transform: translate(-50%, -50%) scale(1.0)
  }
}

@keyframes rotate1 { 
  0% { 
    transform: rotate(0deg) translateY(-100%) rotate(0deg);
  }
  50%{
    transform: rotate(180deg) translateY(-100%) rotate(-180deg);
  }
  100% {  
    transform: rotate(360deg) translateY(-100%) rotate(-360deg);
  }
}

@for $t from 1 through $numOuter {
  @keyframes rotate#{$t} {
    0% {
      transform: rotate(  $t * $unitDeg + (180 - $unitDeg)  + deg)
                 translateY($r + px)
                 rotate(-($t * $unitDeg + (180 - $unitDeg)) + deg);
    }
    50% {
      transform: rotate(  $t * $unitDeg + (360 - $unitDeg)  + deg)
                 translateY($r + px)
                 rotate(-($t * $unitDeg + (360 - $unitDeg)) + deg);
    }
    100% {  
      transform: rotate(  $t * $unitDeg + (540 - $unitDeg)  + deg)
                 translateY($r + px)
                 rotate(-($t * $unitDeg + (540 - $unitDeg)) + deg);
    }
  }
}

@for $u from 1 through $numOuter {
  @for $t from 1 through $numOuter {
    $rotate1: $t * $unitDeg + (180 - $unitDeg);
    $rotate2: ($numOuter - $u + 1) * $unitDeg + $t * $unitDeg + (180 - $unitDeg);
    @keyframes outerShrink#{$u}-#{$t}-1 {
      0% {
        transform: rotate( $rotate1 + deg)
                   translateY($r + px)
                   rotate(-$rotate1 + deg);
      }
      100% {
        transform: rotate( $rotate2 + deg)
                   translateY($r + px)
                   rotate(-$rotate2 + deg);
      }
    }
    @keyframes outerShrink#{$u}-#{$t}-2 {
      0% {
        transform: rotate( $rotate2 + deg)
                   translateY($r + px)
                   rotate(-$rotate2 + deg);
      }
      100% {  
        $deg: if($t > $u, 900, 540);
        transform: rotate( $deg + deg)
                   translateY($r + px)
                   rotate(-$deg + deg);
      }
    }
  }
}

@for $u from 1 through 10 {
  @for $t from 1 through $numInner {
    $rotate: $t * $innerUnitDeg + (180 - $innerUnitDeg);
    @keyframes innerShrink#{$t}-#{$u} {
      0% {
        transform: rotate( $rotate + deg)
                   translateY($innerR * $u / 10 + px)
                   rotate(-$rotate + deg);
      }
      66% {
        transform: rotate( $rotate + deg)
                   translateY($innerR * $u / 10 + px)
                   rotate(-$rotate + deg);
      }
      100% {  
        transform: rotate( $rotate + deg)
                   translateY(0)
                   rotate(-$rotate + deg);
      }
    }
  }
}

@keyframes innerBeforeShrink {
  0% {
    opacity: 1.0;
  }
  100% {  
    opacity: 0;
  }
}

@keyframes innerKeywordShrink {
  0% {
    opacity: 1.0;
  }
  100% {  
    opacity: 0;
  }
}


@for $t from 1 through $numOuter {
  $rotate: $t * $unitDeg + (180 - $unitDeg);
  @keyframes outerStretch#{$t} {
    0% {
      transform: rotate( 180deg)
                 translateY($r + px)
                 rotate(-180deg);
    }
    100% {  
      transform: rotate( $rotate + deg)
                 translateY($r + px)
                 rotate(-$rotate + deg);
    }
  }
}


@for $u from 1 through 10 {
  @for $t from 1 through $numInner {
    $rotate: $t * $innerUnitDeg + (180 - $innerUnitDeg);
    @keyframes innerStretch#{$t}-#{$u} {
      0% {
        transform: rotate(  180 + $rotate  + deg)
                   translateY(0)
                   rotate(-(180 + $rotate) + deg);
      }
      100% {  
        transform: rotate(  360 + $rotate  + deg)
                   translateY($innerR * $u / 10 + px)
                   rotate(-(360 + $rotate) + deg);
      }
    }
  }
}

@for $t from 1 through $numOuter {
  $rotate: $t * $unitDeg + (180 - $unitDeg);
  @keyframes outerRelated#{$t} {
    0% {
      transform: rotate( $rotate + deg)
                 translateY($r + px)
                 rotate(-$rotate + deg);
    }
    20% {  
      transform: rotate( $rotate + deg)
                 translateY($r + 16 + px)
                 rotate(-$rotate + deg);
    }
    100% {
      transform: rotate( $rotate + deg)
                 translateY($r + px)
                 rotate(-$rotate + deg);
    }
  }
}

@for $t from 1 through $numOuter {
  $rotate: $t * $unitDeg + (180 - $unitDeg);
  @keyframes outerHover#{$t} {
    0% {
      transform: rotate( $rotate + deg)
                 translateY($r + px)
                 rotate(-$rotate + deg);
    }
    20% {  
      transform: rotate( $rotate + deg)
                 translateY($r + 20 + px)
                 rotate(-$rotate + deg);
    }
    100% {
      transform: rotate( $rotate + deg)
                 translateY($r + px)
                 rotate(-$rotate + deg);
    }
  }
}

//
// html elements
//
.map {
  position: absolute;
  /* top: 6rem; */
  /* top: 0;
     left: 50%;
     transform: translate(-50%, 0); */
  width: 2 * ($RADIUS + $outerLiSize + 50) + px;
  height: 2 * ($RADIUS + $outerLiSize + 50) + px;
  user-select: none;
}

//
// buttons
//
.btn-wrap {
  text-align: left;
}

.btn {
  background: transparent;
  border: 2px solid #fff;
  position: relative;
  width: 12rem;
  display: inline-block;
  padding: 0.5rem 1rem;
  transition: all 0.2s ease-in-out;
  text-align: center;
  color: #fff;
  outline: none;
  text-shadow: 0 1px 1px rgba(0,0,0,0.1);
  box-shadow: 0 1px 3px rgba(0,0,0,0.1), 0 1px 3px rgba(0,0,0,0.1) inset;
  box-sizing: border-box;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    border-color: #454545;
    box-sizing: border-box;
    border-style: solid;
    width: 1em;
    height: 1em;
    transition: all 0.2s ease-in-out;
    border-color: #fff;
  }

  &:before {
    top: -6px;
    left: -6px;
    border-width: 2px 0 0 2px;
    z-index: 5;
  }

  &:after {
    bottom: -6px;
    right: -6px;
    border-width: 0 2px 2px 0;
  }

  &:hover:before,
  &:hover:after {
    width: calc(100% + 12px);
    height: calc(100% + 12px);
    border-color: #fff
  }

  &:hover {
    color: #3cb2df;
    background-color: #fff;
    border-color: #3cb2df;
  }
}

.input-wrap {
  width: 20rem;
  margin-bottom: 1rem;
}

.input-desc {
  font-size: 1rem;
  text-align: left;
  color: #fff;
  font-size: 0.6rem;
  white-space: nowrap;
  overflow: hidden;
  /* zoom: 0.6; */
}

.input-box {
  position: relative;
  display: flex;
  color: #fff;
  transition: all 0.2s ease-in-out;
  text-align: center;
  text-shadow: 0 1px 1px rgba(0,0,0,0.1);
  box-shadow: 0 1px 3px rgba(0,0,0,0.1), 0 1px 3px rgba(0,0,0,0.1) inset;

  &:hover,
  & > .assocForm:focus {
    color: #3cb2df;
    background-color: #fff;
    border-color: #3cb2df;
  }

  .assocForm {
    display: inline-block;
    width: 100%;
    font-size: 100%;
    outline: none;
    padding: .5em 1.5rem .5rem .5rem;
    border: 2px solid #fff;
    overflow: hidden;
  }

  .input-clear {
    position: absolute;
    display: inline-block;
    right: .5rem;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
  }
}


/*
 * re-search button
 */
.re-search {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5rem;
  height: 5rem;
  padding-top: .9rem;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  text-align: center;
  font-size: .9rem;
  color: rgb(6, 73, 142);
  background: linear-gradient(135deg, #f1f7fa 0%, #b4cde3 100%);
  box-shadow: 4px 4px 7px rgba(3, 16, 15, 0.6);
  box-sizing: border-box;
}

.re-search:hover {
  color: rgb(254,158,13);
  cursor: pointer;
}

.re-search > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -15%);
  width: 1.5rem;
  height: 1.5rem;
  fill: rgb(6, 73, 142);
}

.re-search:hover > svg {
  fill: rgb(254,158,13);
}

//
// copyright
//
.map-copyright {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  color: #fff;
  text-align: right;
  font-size: .8rem;
}


//
// cover
//
.stop-operate {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

//
// navi
//
.form1 {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 3.5rem;
  left: 0;
  margin: 1rem;
}

.form1 > button:hover {
  cursor: pointer;
}

.right-navi {
  position: absolute;
  top: 3.5rem;
  right: 0;
  margin: 1rem;
}

.shelf-btn {
  display: block;
  border: 2px solid #fff;
  padding: 0.5rem;
  transition: all 0.2s ease-in-out;
  text-align: right;
  color: #fff;
  background: transparent;
  text-shadow: 0 1px 1px rgba(0,0,0,0.1);
  box-shadow: 0 1px 3px rgba(0,0,0,0.1), 0 1px 3px rgba(0,0,0,0.1) inset;

  & > span {
    vertical-align: middle;
  }

  & > img {
    display: inline-block;
    width: .8rem;
    margin-left: .5rem;
    vertical-align: middle;
  }

  &:hover {
    color: #3cb2df;
    background-color: #fff;
    border-color: #3cb2df;
  }

  &:nth-child(1) {
    width: 13rem;
    margin-bottom: 1rem;
  }

  &:nth-child(2) {
    width: 8rem;
    margin: 0 0 0 auto;
  }
}

//
// relation themes and keywords
//
.relations {
  position: absolute;
  top: 12.5rem;
  left: 1rem;
}

.relation {
  margin-top: 1rem;
  color: #FFF;
  text-align: left;
  user-select: none;
}

.relation-btn {
  display: flex;
  justify-content: space-between;
  border: 2px solid #fff;
  position: relative;
  width: 12rem;
  padding: 0.5rem;
  transition: all 0.2s ease-in-out;
  text-align: center;
  color: #fff;
  background: transparent;
  text-shadow: 0 1px 1px rgba(0,0,0,0.1);
  box-shadow: 0 1px 3px rgba(0,0,0,0.1), 0 1px 3px rgba(0,0,0,0.1) inset;
}

.relation-btn > span {
  display: block;
}

.relation-btn.wide {
  display: flex;
}
.relation-btn.narrow {
  display: none;
}

.relation-list {
  max-width: 12rem;
  margin-top: .25rem;
  padding: .5rem;
  border: solid 2px #E0FFFF;
  border-radius: .15rem;
  font-size: .95rem;
  background: linear-gradient(315deg, #1e9bd7 0%, #1a4e96 100%);
  animation: relationListShow 0.5s linear 0s;
}

@keyframes relationListShow {
  from {
    opacity: 0;
    transform: translateY(5px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.relation-ul {
  margin-top: .25rem;
}

.relation-ul > li {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: .25rem 0;
  border-bottom: dashed 1px #FFF;
  line-height: .95rem;
}

.li-title {
  position: relative;
  display: block;
  white-space: nowrap;
  overflow: hidden;
}

.li-title > input {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}

.li-title > span {
  padding-left: 1rem;
}

.right-arrow {
  position: relative;
  margin-left: .25rem;
  vertical-align: middle;
  cursor: pointer;
}

.right-arrow > a > img {
  width: .8rem;
}

.re-search-btn-wrap {
  width: 100%;
}

.re-search-btn {
  display: block;
  vertical-align: middle;
  width: 8rem;
  margin: .5rem auto 0;
  text-align: center;
  border: 1px solid #FFF;
  transition: all .5s;
}

.re-search-btn > span {
  display: inline-block;
  padding: .25rem 0 .2rem .2rem;
}

.re-search-btn > svg {
  display: inline-block;
  padding-top: .2rem;
}

.re-search-btn > svg > path {
  fill: #FFF;
}

.reSearch.re-search-btn {
  color: #000;
  background-color: rgb(255, 147, 0);
  border-color: transparent;
  cursor: pointer;
}

.reSearch.re-search-btn > svg > path {
  fill: #000;
}

.list-arrow {
  transition: all .5s;
}

.list-arrow.open {
  transform: rotate(-180deg);
}


@media (max-width: 1500px) {
  .input-wrap {
    width: 14rem;
  }
  .btn {
    width: 9.5rem;
  }
  .relation-btn {
    width: 9.5rem;
  }
  .relation-list {
    max-width: 9.5rem;
  }
}

@media (max-width: 1030px) {
  .map-copyright {
    bottom: .5rem;
    right: .5rem;
    zoom: 0.75;
  }
}

@media (max-width: 1000px) {
  .map {
    /* top: 50%;
       transform: translate(-50%, -50%); */
  }
  .relations {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 17.5rem;
    max-width: calc(100% - 1rem);
    top: auto;
    bottom: 4.5rem;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .relation-btn {
    width: auto;
  }
  .relation-btn.wide {
    display: none;
  }
  .relation-btn.narrow {
    display: flex;
  }

  .relation-list {
    position: absolute;
    bottom: 2.5rem;
    max-width: 90%;
  }
}

@media (max-width: 800px) {
  .map {
    font-size: 1.5rem;
  }
  .right-navi {
    display: flex;
    justify-content: space-between;
    right: auto;
    top: auto;
    left: 0;
    bottom: 2.25rem;
    margin: 0 .5rem;
    width: calc(100% - 1rem);
  }
  .shelf-btn {
    display: block;
    border: none;
    padding: 0;
    font-size: .9rem;
    white-space: nowrap;

    &:nth-child(1) {
      width: auto;
      margin-bottom: 0;
    }

    &:nth-child(2) {
      width: auto;
      margin: 0;
    }

    & > img {
      display: inline-block;
      margin-left: .25rem;
      vertical-align: top;
    }
  }
}

@media (max-width: 512px) {
  .container {
    /* height: calc(100vh - 2.75rem);
       margin-top: 2.75rem; */
    padding-top: 2.75rem;
  }
  .map {    
    font-size: 1.75rem;
  }
  .form1 {
    top: 2.75rem;
    flex-direction: row;
    align-items: flex-end;
    margin: .5rem;
    width: calc(100% - 1rem);
  }
  .input-wrap {
    margin: 0;
    flex-grow: 1;
  }
  .btn {
    margin: 0 0 0 .75rem;
    width: auto;
    white-space: nowrap;
  }
}
</style>
