<template>
  <div :class="$style.wrap">
    <div :class="$style.category">
      <div :class="$style.title">
        <h1><img src="@/assets/right.svg"> <span>書棚で見るテーマ一覧</span></h1>
      </div>
      <div :class="$style.shelf1">
        <div :class="$style.shelf2">
          <div :class="$style.shelf3">
            <div :class="$style.shelf_roof"></div>
            <div v-for="category in categories" :key="category[0]" :class="$style.categories">
              <button @click="toggle(category[2])" :class="$style.shelf_label" class="keyboard-focus">
                <img :src="getCategoryImage(category)"
                  :alt="category[1]"
                  :class="$style.category_image">
              </button>
              <template v-if="category[4]">
                <div v-for="theme in category[3]" :key="theme.id" :class="$style.themes">
                  <div v-for="image in theme.images" :key="image.source">
                    <router-link
                      :class="$style.books_image_wrap"
                      :to="getThemeTo(theme.nameEn)"
                      v-tooltip="getThemeTip(theme.name)">
                      <img :src="getThemeImage(image.source)" :class="$style.books_image">
                    </router-link>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div :class="$style.theme_list">
        <h2 v-if="currentCategory[1]">{{ currentCategory[0] }} {{ currentCategory[1] }}
        </h2>
        <ul>
          <li v-for="theme in currentCategory[3]" :key="theme.id" :class="$style.theme_li">
            <h3>
              <router-link :to="getThemeTo(theme.nameEn)">
                <img src="@/assets/right-btn-blue.svg" /> {{ theme.name }}
              </router-link>
            </h3>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
const categories = [
  [1, "ITとメディア", "it_and_media", [], false],
  [2, "国際社会と戦争", "global_society", [], false],
  [3, "働くことと企業", "working_and_company", [], false],
  [4, "お金と経済", "money_and_economy", [], false],
  [5, "政治と行政", "polytical_system", [], false],
  [6, "産業", "industry", [], false],
  [7, "法律", "legal_issues", [], false],
  [8, "少子高齢化社会", "aging_society", [], false],
  [9, "男と女、家族", "relationship_and_family", [], false],
  [10, "健康と医療", "health_and_medical_care", [], false],
  [11, "食生活", "dietary_life", [], false],
  [12, "住まい", "way_of_living", [], false],
  [13, "テクノロジー", "technology", [], false],
  [14, "ヒトと生きもの", "mouse_and_man", [], false],
  [15, "地球と宇宙", "space_and_earth", [], false],
  [16, "サイエンス", "science", [], false],
  [17, "音楽", "music", [], false],
  [18, "エンタテインメントとアート", "entertainment_and_art", [], false],
  [19, "カルチャーとホビー", "culture_and_hobbies", [], false],
  [20, "スポーツ", "sports", [], false],
  [21, "文学", "literature", [], false],
  [22, "旅", "travel", [], false],
  [23, "各国・地域情報", "nations_and_area", [], false],
  [24, "差別", "discrimination", [], false],
  [25, "日本と日本人", "japan_and_japanese", [], false],
  [26, "コミュニケーション", "communication", [], false],
  [27, "言語と語学", "language", [], false],
  [28, "心理", "psychology", [], false],
  [29, "歴史", "history", [], false],
  [30, "民族と民俗", "ethnic_and_folk_culture", [], false],
  [31, "哲学と思想", "philosophy_and_idea", [], false],
  [32, "宗教", "religion", [], false]
]

export default {
  name: 'Category',
  components: {
  },
  data() {
    return {
      categories: categories,
      category: '',
      currentCategory: [0, '', '', [], false],
      data: {},
      finishedFetch: false
    }
  },
  watch: {
    $route() {
      this.closeAll()
      if ( this.$route.params.category ) {
        this.category = this.$route.params.category.replace('.html', '')
        this.openCategory()
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    getCategoryImage(category) {
      if ( category === undefined ) return
      if ( category[4] )
        return `https://category.shinshomap.info/images/label/label_${category[0]}c.jpg`
      else
        return `https://category.shinshomap.info/images/label/label_${category[0]}.jpg`
    },
    getThemeImage(src) {
      return src.replace('http://shinshomap.associationpress.jp/api', '/shinshomap_api')
    },
    getThemeTip(name) {
      return `テーマ: ${name}`
    },
    fetchData() {
      this.finishedFetch = false
      if ( this.$route.params.category ) {
        this.category = this.$route.params.category.replace('.html', '')
      }
      const api = '/shinshomap_api/categories.json'
      fetch(api, {mode: 'cors', credentials: 'include'})
        .then( response => response.json() )
        .then( json => {
          json.categories.forEach( (category, i) => {
            this.$set(this.categories[i], 3, category.themes)
          })
          this.openCategory()
          this.finishedFetch = true
        })
    },
    openCategory() {
      if ( ! this.category ) return
      this.categories.forEach( (category, i) => {
        if ( category[2] == this.category ) {
          this.$set(this.categories[i], 4, true)
          this.currentCategory = category
        }
      })
    },
    closeAll() {
      this.categories.forEach( (category, i) => {
        this.$set(this.categories[i], 4, false)
      })
    },
    toggle(target) {
      this.categories.forEach( (category, i) => {
        if ( category[2] == target ) {
          this.$set(this.categories[i], 4, ! category[4])
          this.currentCategory = category
        }
      })
    },
    getThemeTo(theme) {
      return `/theme/${theme}.html`
    },
  }
}

</script>

<style module>
.wrap {
  display: inline-block;
  /* height: 100vh; */
  background-color: #deeef6;
}

.category {
  min-height: calc(100vh - 2.75rem);
  margin: 0 1rem 1rem 1rem;
  padding: 5rem 1rem 1rem;
  box-shadow: 0 0 15px rgba(0, 0, 0, .3);
  border-bottom: 5px solid rgb(0, 99, 158);
  background-color: #FFF;
}

.title {
  margin-bottom: .5rem;
  text-align: left;
}

.title > h1 > span,
.title > h1 > img {
  vertical-align: middle;
}

.title > h1 > img {
  width: .85rem;
}

.shelf1 {
  margin-bottom: 2rem;
  border-style: solid;
  border-width: 2px;
  border-top-color: rgb(158, 118, 44);
  border-left-color: rgb(158, 118, 44);
  border-bottom-color: rgb(96, 65, 23);
  border-right-color: rgb(96, 65, 23);
}

.shelf2 {
  display: inline-block;
  border: solid 17px rgb(117, 87, 32);
}

.shelf3 {
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 390px;
  border-style: solid;
  border-width: 2px;
  border-top-color: rgb(96, 65, 23);
  border-left-color: rgb(96, 65, 23);
  border-bottom-color: rgb(158, 118, 44);
  border-right-color: rgb(158, 118, 44);
  background-color: #000;
}
.shelf_roof {
  position: absolute;
  top: 0;
  width: 100%;
  height: 0;
  border-top: 10px solid rgb(54, 37, 12);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

.categories {
  display: flex;
  align-items: flex-end;
}

.shelf_label {
  margin-right: 1px;
}

.shelf_label:focus {
  outline: #deeef6 solid;
}

.themes {
  display: flex;
}

.books_image_wrap {
  display: inline-block;
}

.books_image {
  height: 340px;
}

.category_image {
  height: 360px;
  cursor: pointer;
}

.theme_list {
  text-align: left;
}

.theme_list > h2 {
  margin-bottom: .9rem;
  padding: .1rem .3rem;
  color: #fff;
  background-color: #0081c1;
  font-weight: 700;
}

.theme_li {
  margin-bottom: .5rem;
}
.theme_li > h3 {
  padding-left: .85rem;
  text-indent: -.75rem;
}

.theme_li > h3 > a > img {
  width: .5rem;
  vertical-align: middle;
}
</style>
