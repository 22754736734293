<template>
  <div class="header">
    <header>
      <div class="header-left">
        <div class="page-title">
          <router-link :to="{name: 'Home'}">
            <img class="logo" src="@/assets/shinshomap-logo.svg" alt="新書マップ">
          </router-link>
        </div>
      </div>
      <div class="header-center">
        <div v-show="showHomeBtn" class="back">
          <router-link :to="{name: 'Home'}"><div class="back-result btn">検索画面へ戻る</div></router-link>
        </div>
      </div>
      <div class="header-right">
        <router-link v-show="isHome()" to="/notice" class="header-link">お知らせ<img src="@/assets/right-btn-box.svg"></router-link>
        <router-link v-show="isHome()" to="/help" class="header-link">ヘルプ<img src="@/assets/right-btn-box.svg"></router-link>
        <a class="kaze" href="http://kaze.shinshomap.info/">
          <img src="@/assets/kaze.svg" alt="web magazine 風">
        </a>
        <button class="menu-btn keyboard-focus" :class="{active: showMenu}" @click="toggleMenu()">
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </header>
    <transition name="fade">    
      <Menu v-if="showMenu" v-on:hide-menu="toggleMenu"></Menu>
    </transition>
  </div>
</template>

<script>
import Menu from './Menu.vue'

export default {
  name: 'Header',
  components: {
    Menu,
  },
  data() {
    return {
      throughHome: false,
      showMenu: false,
    }
  },
  watch: {
    $route() {
      if ( this.isHome() ) {
        this.throughHome = true
      }
    },
  },
  computed: {
    showHomeBtn() {
      return this.throughHome && ! this.isHome()
    },
    // showHome() {
    //   return this.isHome()
    // }
  },
  mounted() {
    if ( this.isHome() ) {
      this.throughHome = true
    }
  },
  methods: {
    toggleMenu() {
      this.showMenu = ! this.showMenu
    },
    isHome() {
      return this.$route.name === 'Home' ||
             this.$route.name === 'SearchCompatibility'
    }
  }
}
</script>

<style scoped>
.header {
  position: fixed;
  width: 100%;
  height: 3.5rem;
  top: 0;
  border-bottom: 2px solid rgb(0, 99, 158);
  background-color: #FFF;
  z-index: 100;
}

header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  max-width: 1024px;
  margin: 0 auto;
  padding: .5rem 0 .25rem 0;
}

.header-center,
.header-right,
.header-left {
  display: flex;
  height: 2.75rem;
  align-items: center;
}

.page-title > a {
  display: block;
  padding-left: 1rem;
}

.logo {
  height: 2.75rem;
}

.back {
}

.back-result.btn {
  width: auto;
  margin: 0;
  padding: .35rem 1rem;
  color: #2a7ab5;
  border-color: #2a7ab5;
}

.back-result.btn:before,
.back-result.btn:after {
  border-color: #2a7ab5;
}

.back-result.btn:hover:before,
.back-result.btn:hover:after {
  border-color: #2a7ab5;
}

.back-result.btn:hover {
  color: #FFF;
  background-color: #2a7ab5;
  border-color: #2a7ab5;
}

.header-link {
  display: inline-block;
  margin: 1rem 1rem 0 0;
}

.header-link > img {
  display: inline-block;
  height: .75rem;
  margin-left: .2rem;
  vertical-align: baseline;
}

.kaze {
  display: block;
  margin-right: 1rem;
}

.kaze img {
  height: 2rem;
  aspect-ratio: 25 / 8;
}


/*===========================
  menu button
=============================*/
.menu-btn {
  position: relative;
  width: 1.2rem;
  height: .9rem;
  border: none;
  cursor: pointer;
}

.menu-btn span {
  position: absolute;
  left: 0;
  width: 100%;
  height: .15rem;
  background-color: #1a4e96;
  border-radius: 1rem;
}
.menu-btn, .menu-btn span {
  display: inline-block;
  transition: all .5s;
  box-sizing: border-box;
}
.menu-btn span:nth-of-type(1) {
  top: 0;
}
.menu-btn span:nth-of-type(2) {
  top: .38rem;
}
.menu-btn span:nth-of-type(3) {
  bottom: 0;
}

.menu-btn.active span:nth-of-type(1) {
  transform: translateY(.375rem) rotate(-315deg);
}
.menu-btn.active span:nth-of-type(2) {
  opacity: 0;
}
.menu-btn.active span:nth-of-type(3) {
  transform: translateY(-.375rem) rotate(315deg);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

@media (max-width: 1030px) {
  .header-right {
    padding-right: 1rem;
  }
}

@media (max-width: 700px) {
  .header-link {
    display: none;
  }
}

@media (max-width: 512px) {
  .header {
    height: 2.75rem;
  }
  .kaze {
    display: none;
  }
  .header-center,
  .header-right,
  .header-left,
  .logo {
    height: 2rem;
  }
  .header-right {
    padding-right: .5rem;
  }
  .back-result.btn {
    padding: .2rem;
    font-size: .9rem;
  }
  .page-title > a {
    padding-left: .35rem;
  }

}
</style>
