import parser from 'fast-xml-parser'
const url = {
  // assoc: '/demo/shinshomap/mapsearch/assoc.do',
  // search: '/demo/shinshomap/mapsearch/search.do',
  // keyword: '/demo/shinshomap/mapsearch/keyword.do',
  // assoc: 'http://puebla.cs.nii.ac.jp/demo/shinshomap_new/getassoc/gss3',
  // search: 'http://puebla.cs.nii.ac.jp/demo/shinshomap_new/getassoc/gss3',
  // keyword: 'http://puebla.cs.nii.ac.jp/demo/shinshomap_new/getassoc/gss3'
  // assoc: 'https://teotihuacan.cs.nii.ac.jp/shinshomap_assoc/getassoc/gss3',
  // search: 'https://teotihuacan.cs.nii.ac.jp/shinshomap_assoc/getassoc/gss3',
  // keyword: 'https://teotihuacan.cs.nii.ac.jp/shinshomap_assoc/getassoc/gss3'
  // assoc: '/shinshomap_assoc/getassoc/gss3',
  // search: '/shinshomap_assoc/getassoc/gss3',
  // keyword: '/shinshomap_assoc/getassoc/gss3'
  assoc: '/shinshomap_assoc/gss3',
  search: '/shinshomap_assoc/gss3',
  keyword: '/shinshomap_assoc/gss3',
  vector: 'https://imagine.bookmap.info/get_gss.php?accessPoint=shinshomap',
}

class GETAssoc {
  constructor(handle) {
    this.handle = handle || 'shinshomap_theme'
    this.props = 'title'
    this.header = `<?xml version="1.0" encoding="UTF-8"?>
<gss version="3.0">
<assoc target="${this.handle}" niwords="70" cutoff-df="0" stage1-sim="WT_SMARTAW" narticles="10" nkeywords="12" yykn="10" nacls="1" nkcls="1" a-offset="0" a-props="${this.props}" cross-ref="aw,wa" stage2-sim="WT_SMARTWA">`
    this.footer = '</assoc></gss>'
    this.data = []
  }

  makeKeywordQuery( queries ) {
    this.assocType = 'keyword'
    this.data = [this.header]
    const vec = queries.map( q => `[&quot;${q}&quot;,1]` )
    this.data.push(`<article vec="[${vec.join(',')}]"/>`)
    this.data.push(this.footer)
  }
 
  makeArticleQuery( names ) {
    this.assocType = 'assoc'
    this.data = [this.header]
    names.forEach( name => {
      this.data.push(`<article name="${name}"/>`)
    })
    this.data.push(this.footer)
  }

  makeArticleQuery2( names, narticles ) {
    narticles = narticles || 10
    this.assocType = 'assoc'
    this.data = [`<?xml version="1.0" encoding="UTF-8"?>
<gss version="3.0">
<assoc target="${this.handle}" niwords="70" stage1-sim="WT_SMARTAW" narticles="${narticles}" a-offset="0" a-props="${this.props}" stage2-sim="WT_SMARTWA">`]
    names.forEach( name => {
      this.data.push(`<article name="${name}"/>`)
    })
    this.data.push(this.footer)
  }

  makeFreetextQuery( query )  {
    this.assocType = 'search'
    this.data = [this.header]
    if ( query.length > 20 ) {
      this.data.push(`<freetext stemmer="auto">${query}</freetext>`)
    } else {
      this.data.push(`<freetext cutoff-df="0" stemmer="auto">${query}</freetext>`)
    }
    this.data.push(this.footer)
  }

  makeVectorQuery( article ) {
    this.assocType = 'vector'
    this.data = [`<?xml version="1.0" encoding="UTF-8"?>
<gss version="3.0">
<getprop target="${this.handle}" a-props="vec">
<article name="${article}" />
</getprop>
</gss>`]
  }

  setProps( props ) {
    this.props = props
  }

  async assoc() {    
    const method = 'POST'
    const mode = 'cors'
    const response = await fetch(url[this.assocType], {method, body: this.data.join("\n"), mode})
    const xml = await response.text()
    const jsonObj = parser.parse(xml.replace(/\n/g, ""), { ignoreAttributes : false })
    this.data = []
    return jsonObj.gss?.result ? jsonObj.gss.result : {}
  }
}

export default GETAssoc
