<template>
  <div class="not-found-wrap">
    <div class="not-found">
      <h2>Not Found: お探しのページは見つかりませんでした</h2>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from './Footer.vue'

export default {
  name: 'NotFound',
  components: {
    Footer,
  }
}
</script>


<style scoped>
.not-found-wrap {
  max-width: 1024px;
  margin: 0 auto;
}
.not-found {
  height: calc(100vh - 1.75rem);
  box-shadow: 0 0 15px rgba(0,0,0,.3);
  border-bottom: 5px solid #00639e;
  background: #fff;
}

h2 {
  padding: 4.5rem 1rem 0;
}

</style>
