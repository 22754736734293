<template>
  <div class="about">
    <div class="main">
      <h1>お知らせ</h1>
      <p>新書マップの新刊収録，テーマ追加，機能追加などの更新情報をお送りします。</p>

      <div v-for="news in notices" :key="news.text">
        <h2>{{ dateString(news.date) }}</h2>
        <p v-if="news.link"><a :href="news.link">{{ news.content }}</a></p>
        <p v-else>{{ news.content }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Notice',
  metaInfo() {
    return {
      title: 'お知らせ',
    }
  },
  data() {
    return {
      notices: []
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      const api = `/shinshomap_api/index.json`
      fetch(api, {mode: 'cors', credentials: 'include'})
        .then( response => {
          if ( response.status >= 400 ) {
            this.$router.replace('/not-found')
            return
          }
          return response.json()
        })
        .then( json => {
          if ( ! json?.data ) return
          const notices = json.news.map( news => {
            return {
              date: new Date(news.date),
              content: news.text,
              link: news.link
            }
          })
          notices.push({
            date: new Date(json.data.lastmod),
            content: `${json.data.bookInfo[0].lastpub}までの新刊${json.data.bookInfo[0].newBooks}冊が追加され，収録数が${json.data.bookInfo[0].total}冊となりました。`,
            link: ""
          })
          this.notices = notices.sort( (a, b) => {
            return b.date - a.date
          })
        })
    },
    dateString( date ) {
      return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`
    }
  }
}
</script>

<style scoped>
.main {
  max-width: 1024px;
  min-height: calc(100vh - 2.75rem);
  margin: 0 auto 1rem;
  padding: 4.5rem 1rem 1rem;
  box-shadow: 0 0 15px rgba(1, 35, 56, 0.3);
  border-bottom: 5px solid rgb(0, 99, 158);
  background: #FFF;
  text-align: left;
}

h1 {
  display: inline-block;
  padding: 2rem 1rem;
  margin: .5rem 0 1rem;
  font-weight: bold;
  background-color: #deeef6;
  border-left: solid 2rem #0081c1;
}

h2 {
  margin: 2rem 0 1rem;
  padding: .25rem .5rem;
  font-size: .95rem;
  font-weight: bold;
  color: #FFF;
  background-color: #0081c1;
}

p {
  text-indent: 1rem;
  margin-bottom: .5rem;
  line-height: 1.4rem;
}

a {
  /* color: #0066c0; */
}

a:hover {
  text-decoration: underline;
}

@media (max-width: 1024px) {
  .main {
    margin: 0 .5rem;
  }
}

@media (max-width: 512px) {
  .main {
    padding: 3.5rem .5rem 1rem;
  }
}
</style>
