import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home.vue'
import About from '../components/About.vue'
import Help from '../components/Help.vue'
import Notice from '../components/Notice.vue'
import Theme from '../components/Theme.vue'
import Book from '../components/Book.vue'
import Category from '../components/Category.vue'
import NotFound from '../components/NotFound.vue'
import VueGtag from "vue-gtag";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "home" */ '../components/Home.vue')
    component: Home
  },
  {
    path: '/search.php',
    name: 'SearchCompatibility',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/help',
    name: 'Help',
    component: Help
  },
  {
    path: '/notice',
    name: 'Notice',
    component: Notice
  },
  {
    path: '/theme/:theme',
    name: 'Theme',
    component: Theme
  },
  {
    path: '/book/:book',
    name: 'Book',
    component: Book
  },
  {
    path: '/genre/',
    name: 'CategoryRoot',
    component: Category
  },
  {
    path: '/genre/:category',
    name: 'Category',
    component: Category
  },
  {
    path: '*',
    name: 'notFound',
    component: NotFound,
    meta: { title: 'お探しのページは見つかりませんでした' }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

if ( process.env.VUE_APP_GA_ID ) {
  Vue.use(VueGtag, {
    config: {id: process.env.VUE_APP_GA_ID}
  }, router);
}

export default router
