<template>
  <div :class="$style.theme">
    <div :class="$style.wrap">
      <div :class="$style.main">

        <div :class="$style.info">
          <div :class="$style.info_second">
            <div :class="$style.category">{{ data.theme.category.id }}. {{ data.theme.category.name }}</div>
          </div>
          <div :class="$style.info_second">
            <div :class="$style.theme_wrap">
              <div :class="$style.theme_name">
                <div>テーマ<br>theme</div>
              </div>
              <div :class="$style.theme_title">
                <div>{{ data.theme.name }}</div>
              </div>
            </div>
            <div :class="$style.description">{{ data.theme.description }}</div>
          </div>
        </div>

        <div v-show="finishedFetch" :class="$style.shelf_book">
          <div :class="$style.shelf">
            <div :class="$style.shelf_border" v-for="(image, i) in data.theme.images" :key="image.source">
              <div :class="$style.shelf_floor"></div>
              <router-link v-if="i==0" :to="{name: 'Category', params: {category: data.theme.category.nameEn + '.html'}}" class="keyboard-focus" :class="$style.shelf_label">
                <img :src="getShelfLabel()">
              </router-link>
              <button class="keyboard-focus" :class="$style.shelf_image" @click="showModal(image.source)">
                <img :src="getThemeImage(image.source)">
              </button>
            </div>
            <div :class="$style.shelf_relate">
              <button @click="toImagine()" class="keyboard-focus">
                <span>関連書籍を探す</span> <img src="@/assets/external.svg">
              </button>
            </div>
          </div>
          
          <div v-if="! hasGuide" :class="$style.books">
            <div :class="$style.guide_link" v-if="data.theme.guide">
              <router-link :to="getGuideTo()">
                <img src="@/assets/book.svg"> <img src="@/assets/right-double.svg"> <span>読書ガイドへ</span>
              </router-link>
            </div>
            <div :class="$style.list_header">新書リスト</div>
            <ul :class="$style.list">
              <li :class="$style.book" v-for="book in data.theme.books" :key="book.id">
                <h3 :class="$style.book_title"><router-link :to="getBookTo(book.isbn13)">{{ book.title }}</router-link></h3>
                <p :class="$style.book_info">{{ book.author }}, {{ book.publisher }}, {{ book.publishDate }}<br>
                  {{ book.page }}, ({{ book.seriesInfo }})</p>
                <div v-if="book.otherThemes.length" :class="$style.other_themes">
                  <div v-for="other in book.otherThemes" :key="other.id">
                    <router-link :to="getThemeTo(other.nameEn)" :class="$style.also">
                      <img src="@/assets/also-in.svg"> <span>{{ other.name }}</span>
                    </router-link>
                  </div>
                </div>
              </li>
            </ul>
            <div v-show="finishedFetch">
              <div :class="$style.also_desc">
                <img :class="$style.also_desc_img" src="@/assets/also-in-s.svg">
                <span :class="$style.also_desc_span">他のテーマにも入っています</span>
              </div>
              <div :class="$style.page_top"></div>
            </div>
          </div>

          <div v-if="hasGuide" :class="$style.books">
            <div :class="$style.guide_link">
              <router-link :to="getListTo()">
                <img src="@/assets/book.svg"> <img src="@/assets/left-double.svg"> <span>新書リストへ</span>
              </router-link>
            </div>
            <div :class="$style.list_header">読書ガイド</div>
            <div v-if="data.theme.guide2" :class="$style.guide_para">
              <p v-for="(g,i) in data.theme.guide2" :key="i">{{ g }}</p>
            </div>
          </div>

        </div>

      </div>

      <ThemeAssoc :theme="theme"></ThemeAssoc>
    </div>
    <Modal :src="modalSrc"
      v-on:close="closeModal"
    />
  </div>
</template>

<script>
import ThemeAssoc from './ThemeAssoc.vue'
import Modal from './Modal.vue'
import IMAGINE from '@/modules/imagine'

//const url = 'http://puebla.cs.nii.ac.jp/demo/shinshomap_api/theme.json?q=philosophy_primer'
export default {
  name: 'Theme',
  components: {
    ThemeAssoc,
    Modal,
  },
  metaInfo() {
    return {
      title: this.title,
      titleTemplate: '%s - 新書マップ'
    }
  },
  data() {
    return {
      title: '',
      theme: '',
      data: {
        'theme': {
          'category': {
            id: "0",
            name: "name",
            nameEn: ""
          },
          'nameEn': "",
          books: [],
          guide: '',
          guide2: []
        }
      },
      modalSrc: '',
      hasGuide: false,
      finishedFetch: false
    }
  },
  watch: {
    $route() {
      this.fetchData()
      window.scrollTo({
        top: 0,
        //behavior: "smooth"
      })
    }
  },
  mounted() {
    // console.log(this.$route.params.theme)
    this.fetchData()
  },
  methods: {
    fetchData() {
      // console.log(this.data.theme?.guide?.length)
      this.finishedFetch = false
      if ( ! this.$route.params.theme ) return
      this.theme = this.$route.params.theme.replace('.html', '')

      if ( this.theme.slice(-2) == '_g' ) {
        this.theme = this.theme.slice(0, -2)
        this.hasGuide = true
      } else {
        this.hasGuide = false
      }
      const api = `/shinshomap_api/theme.json?q=${this.theme}`
      fetch(api, {mode: 'cors', credentials: 'include'})
        .then( response => {
          if ( response.status >= 400 ) {
            this.$router.replace('/not-found')
            return
          }
          return response.json()
        })
        .then( json => {
          if ( ! json ) return
          this.data = json
          if ( this.data.theme.guide ) {
            this.data.theme.guide2 = this.data.theme.guide.split("\n")
          }
          this.title = this.data.theme.name
          this.finishedFetch = true
        })
    },
    getBookTo(book) {
      return `/book/${book}.html`
    },
    getThemeTo(theme) {
      return `/theme/${theme}.html`
    },
    getGuideTo() {
      return `./${this.data.theme.nameEn}_g.html`
    },
    getListTo() {
      return `./${this.data.theme.nameEn}.html`
    },
    getLargeThemeTo() {
    },
    getShelfLabel() {
      return `https://category.shinshomap.info/images/label/label_${this.data.theme.category.id}.jpg`
    },
    getThemeImage(src) {
      return src.replace('http://shinshomap.associationpress.jp/api', '/shinshomap_api')
    },
    async toImagine() {
      const data = [this.data.theme.name, this.data.theme.description]
      const titles = this.data.theme.books.map( book => book.title )
      const imagine = new IMAGINE()
      imagine.post(data.concat(titles))
    },
    showModal(src) {
      this.modalSrc = this.getThemeImage(src)
    },
    closeModal() {
      this.modalSrc = ''
    },
  }
}
</script>

<style lang="scss" module>
.theme {
  max-width: 1024px;
  margin: 0 auto;
}

.wrap {
  display: flex;
}

.main {
  flex-grow: 2;
  margin-bottom: 1rem;
  padding: 4.5rem 1rem 1rem;
  box-shadow: 0 0 15px rgba(1, 35, 56, 0.3);
  border-bottom: 5px solid rgb(0, 99, 158);
  background: #FFF;
}

.info {
  width: 100%;
  margin-bottom: 1rem;
}

.info_second {
  display: flex;
}

.theme_wrap {
  display: flex;
  width: 45%;
}

.theme_wrap > div {
  display: flex;
  align-items: center;
}

.description {
  width: 55%;
  border-left: 1px solid white;
}

.category {
  width: 45%;
  padding: .05rem .5rem;
  border-bottom: solid 1px white;
  border-radius: .5rem 0 0 0;
  color: #FFF;
  background-color: rgb(0, 129, 193);
  text-align: left;
  font-weight: bold;
}

.theme_name {
  padding: .2rem;
  color: #FFF;
  background-color: rgb(0, 129, 193);
  font-size: .8rem;
}

.theme_title {
  flex-grow: 2;
  padding: 1rem;
  color: #000;
  background-color: rgb(222, 238, 246);
  text-align: left;
  font-weight: bold;
}

.description {
  padding: .5rem;
  background-color: rgb(232, 232, 232);
  text-align: left;
  font-size: .95rem;
  line-height: 1.4rem;
  min-height: 5rem;
}

.shelf_book {
  display: flex;
}

.shelf {
  width: 45%;
}

.books {
  width: 55%; 
}

.shelf_border {
  display: flex;
  position: relative;
  margin-bottom: .75rem;
  width: calc(100% - 2.5rem);
  height: 340px;
  border: solid .75rem rgb(123, 86, 14);
  background-color: #000;
  text-align: left;
  box-sizing: content-box;
  overflow: hidden;
}

.shelf_floor {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0;
  border-bottom: 60px solid rgb(48, 20, 0);
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
}

.shelf_label,
.shelf_image {
  display: block;
  z-index: 0;
  height: 340px;
}

.shelf_label {
  margin: 0 2px;
}

.shelf_label:focus,
.shelf_image:focus {
  outline: #deeef6 solid;
}

.shelf_label > img ,
.shelf_image > img {
  height: 100%;
}

.shelf_image:hover {
  cursor: pointer;
}

.shelf_relate {
  color: #106a96;
  font-size: .9rem;
  text-align: left;
  cursor: pointer;
}

.shelf_relate > button > span,
.shelf_relate > button > img {
  vertical-align: middle;
}

.shelf_relate > button > img {
  width: .85rem;
}

.list {
  border-bottom: solid 1px #CCC;
}

.list_header {
  margin-bottom: .9rem;
  padding: .1rem .3rem;
  color: #FFF;
  background-color: rgb(0, 129, 193);
  font-size: .9rem;
  font-weight: bold;
  text-align: left; 
  line-height: normal;
}

.guide_link {
  text-align: right;
  margin-bottom: .5rem;
}

.guide_link > a {
  display: inline-block;
  padding: .1rem .25rem;
  border: 1px solid #a2d0ff;
  border-radius: .25rem;
  color: #356096;
  background-color: #e5f0ff;
}

.guide_link > a > img {
  height: .85rem;
  vertical-align: middle;
  aspect-ratio: 1 / 1;
}

.guide_link > a > span {
  vertical-align: middle;
}

.book {
  padding: .75rem .5rem;
  border-top: solid 1px #CCC;
  text-align: left;
}

.book_title {
  color: #106a96;
  /* color: #006; */
  font-weight: bold;
  line-height: 1.2rem;
}

.book_title > a:hover {
  text-decoration: underline;
}

.book > p {
  padding-left: 1rem;
  text-align: left;
}

.book_info {
  margin-top: .5rem;
  line-height: 1.2rem;
  font-size: .9rem;
}

.other_themes {
  margin-top: 1.5rem;
  color: #106a96;
  font-size: .9rem;
  line-height: 1.3rem;
}

.also {
  display: inline-block;
}

.also:hover {
  text-decoration: underline;
}

.also > img {
  height: 1rem;
  vertical-align: middle;
}

.also > span {
  vertical-align: middle;
}

.also_desc {
  margin: .75rem 0;
  color: #F60;
  font-size: .9rem;
  text-align: left;
}

.also_desc_img {
  display: inline-block;
  height: .8rem;
  margin-right: .25rem;
  vertical-align: middle;
}

.also_desc_span {
  vertical-align: middle;
  font-size: .95rem;
}

.guide_para > p {
  text-align: left;
  text-indent: 1rem;
  line-height: 1.5rem;
  margin-bottom: .75rem;
}

.page_top {
  font-size: .9rem;
  text-align: right;
}

@media (max-width: 960px) {
  .wrap {
    display: block;
  }
}

@media (max-width: 512px) {
  .main {
    padding: 4.5rem .5rem 0;
  }
  .info_second {
    display: block;
  }
  .shelf_book {
    display: block;
  }
  .theme_wrap,
  .category,
  .description,
  .shelf,
  .books {
    width: 100%;
  }
  .description {
    border-left: none;
    border-top: 1px solid white;
  }
  .shelf_border {
    width: calc(100% - 1.5rem);
  }

}

</style>
